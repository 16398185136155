import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import ImageFptLogoColor from '../../images/downloads/fpt-logo-color.png';
import ImageFptSymbolTrans from '../../images/downloads/fpt-symbol-trans.png';
import ImageFptSymbolColor from '../../images/downloads/fpt-symbol-color.png';

function InfosDownloads() {

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="text-center pb-4">Downloads</h1>          
        </Col>
      </Row>

      <Row>
        <Col md={8}>
        <Card>
            <Card.Header className="d-flex text-center">
                <h4 className="my-2">Fachpressetage Logos</h4> 
            </Card.Header>
            <Card.Body className='p-0 m-0 DownloadCardBody'>  
              <Row>
                <Col xs={12} md={4} className='text-center'>     
                  <a href={ImageFptLogoColor} target="_blank" rel="noreferrer">             
                  <img src={ImageFptLogoColor} alt="Fpt Logo" className='img-fluid m-3 mt-5' width={230}/></a>
                </Col>
                <Col xs={12} md={4}className='text-center'>   
                <a href={ImageFptSymbolColor} target="_blank" rel="noreferrer">                      
                  <img src={ImageFptSymbolColor} alt="Fpt Logo" className='img-fluid m-3' width={90}/></a>
                </Col>
                <Col xs={12} md={4}className='text-center'>       
                <a href={ImageFptSymbolTrans} target="_blank" rel="noreferrer">               
                  <img src={ImageFptSymbolTrans} alt="Fpt Logo" className='img-fluid m-3' width={90}/></a>
                </Col>
              </Row>           
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default InfosDownloads;