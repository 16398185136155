import React from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqTextVomRBS"
            header="Pressetext zum Vortrags- oder Schwerpunktthema vom RBS"
            visible={props.visible}
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <p>
                        Wenn Sie sich rechtzeitig mit uns in Verbindung setzen, können wir auch die "Pressefassung" Ihres Vortrags in Zusammenarbeit mit Ihrem Referenten oder ihrer Referentin vorab für Sie erar&shy;bei&shy;ten. Dies spart Vorbereitungszeit bei der Ausarbeitung der Vor&shy;trags&shy;version und steigert erfahrungsgemäß auch die Nach&shy;druck&shy;bereitschaft der Redakteur:innen.
                    </p>
                </Col>
                <Col xs={12} lg={6}>
                <Alert>
                        <b>Hinweis:</b> Interesse sollten Sie rechtzeitig anmelden; unsere Kapazität ist begrenzt.
                    </Alert>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;