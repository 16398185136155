import React, { useRef, useEffect } from "react";

import { Container, Row, Col, Button } from 'react-bootstrap';
import RbsLogoSlider from '../../controls/RbsLogoSlider';
import { ReactComponent as ImageChevronLeft } from '../../../images/chevron-left.svg';
import { ReactComponent as ImageChevronRight } from '../../../images/chevron-right.svg';

import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/global";
import { loadFirmenteilnehmer } from "../../../store/actions/fpt";
import { settings } from "../../../config/settings";

const countCols = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 4
}

function Firmen2025() {

  const idFpt = 'fpt036';
  const dispatch = useDispatch();
  const firmen = useSelector(state => state.fpt[idFpt].firmen);

  useEffect(() => {
    dispatch(loadFirmenteilnehmer(idFpt));
  }, []);

  const sliderRef = useRef();

  const bttnPrevClick = () => {
    dispatch(setLoading(true));
    sliderRef.current.movePrev();
  }

  const bttnNextClick = () => {
    dispatch(setLoading(false));
    sliderRef.current.moveNext();
  }

  const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  return (
    <Container>
      <Row>
        <Col className="text-center">
          <h1 className="pb-2 mb-2">Fachpressetage 2025
          </h1>
          <p className="lead pb-4">Diese Firmen sind bislang angemeldet :</p>
        </Col>
      </Row>
      <Row className='mb-5'>
        <Col>
          <RbsLogoSlider
            ref={sliderRef}
            countCols={countCols}
            logos={firmen && shuffle(firmen.map(f => ({
              url: f.logoFile && settings.network.logoUrl + f.logoFile,
              description: f.firmenname
            })))}
            speed={3000}
          />
        </Col>
      </Row>
      <Row className='mt-5 text-center'>
        <Col>
          <Button variant='rbs-button-orange' size='lg' className='me-2' onClick={bttnPrevClick}><ImageChevronLeft width={16} height={16} fill='#fff' /></Button>
          <Button variant='rbs-button-orange' size='lg' href="/Anmeldung">Zur Anmeldung</Button>
          <Button variant='rbs-button-orange' size='lg' className='ms-2' onClick={bttnNextClick}><ImageChevronRight width={16} height={16} fill='#fff' /></Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Firmen2025;