import React, { useState } from 'react';
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Slide1 from '../../images/home/homeslider-1.jpg';
import Slide2 from '../../images/home/homeslider-2.jpg';
import Slide3 from '../../images/home/homeslider-3.jpg';
import Slide4 from '../../images/home/homeslider-4.jpg';

function Homeslider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <React.Fragment>
      <div className='pb-5 pb-lg-0'>
        <Carousel pause={false} activeIndex={index} controls={true} indicators={true} onSelect={handleSelect} interval={8000}>
          <Carousel.Item className="HomesliderItem" style={{ backgroundImage: `url(${Slide1})` }}>
            <h1 className='text-white d-lg-none HomeSliderHeadlineSmall'>Lebendiger Marktplatz<br />für Industrie und Fachpresse</h1>
            <img src={Slide1} alt="Fachpressetage" className='img-fluid d-lg-none' />
            <Container className='mb-lg-2'>
              <Row className='ms-lg-5 ms-xxl-0'>
                <Col lg="10">
                  <h1 className='pb-4 pt-5 mt-lg-5 d-none d-lg-block'>Lebendiger Marktplatz für Industrie und Fachpresse</h1>
                  <p className='lead my-5 mt-lg-0'>
                    Sie suchen Kontakt zu Redakteurinnen und Redakteuren der Auto&shy;matisierungs-Branche? Hier sind Sie richtig!
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item className="HomesliderItem" style={{ backgroundImage: `url(${Slide2})` }}>
            <h1 className='text-white d-lg-none HomeSliderHeadlineSmall'>Multi-Pressekonferenz</h1>
            <img src={Slide2} alt="Fachpressetage" className='img-fluid d-lg-none' />
            <Container>
              <Row className='ms-lg-5 ms-xxl-0'>
                <Col lg="10">
                  <h1 className='pb-4 pt-5 mt-lg-5 d-none d-lg-block'>Multi-Pressekonferenz</h1>
                  <p className='lead my-5 mt-lg-0'>
                    Fachpressetage sind eine wirkungsvolle Alternative oder Ergänzung zur eigenen Pressekonferenz. Hier präsentieren Sie Ihr
                    Thema vor einem großen Fachpressepublikum.
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item className="HomesliderItem" style={{ backgroundImage: `url(${Slide3})` }}>
            <h1 className='text-white d-lg-none HomeSliderHeadlineSmall'>Pressegespräche</h1>
            <img src={Slide3} alt="Fachpressetage" className='img-fluid d-lg-none' />
            <Container>
              <Row className='ms-lg-5 ms-xxl-0'>
                <Col lg="10">
                  <h1 className='pb-4 pt-5 mt-lg-5 d-none d-lg-block'>Pressegespräche</h1>
                  <p className='lead my-5 mt-lg-0'>
                    Pflegen und erweitern Sie Ihre Pressekontakte. Hier lernen Sie die wichtigsten Redakteurinnen und Redakteure persönlich kennen.
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item className="HomesliderItem" style={{ backgroundImage: `url(${Slide4})` }}>
            <h1 className='text-white d-lg-none HomeSliderHeadlineSmall'>Die ganze Welt<br />der Automatisierung</h1>
            <img src={Slide4} alt="Fachpressetage" className='img-fluid d-lg-none' />
            <Container>
              <Row className='ms-lg-5 ms-xxl-0'>
                <Col lg="10">
                  <h1 className='pb-4 pt-5 mt-lg-5 d-none d-lg-block'>Die ganze Welt der Automatisierung</h1>
                  <p className='lead my-5 mt-lg-0'>
                    Hier erhalten Redakteurinnen und Redakteure einen Überblick über die verschiedenen Facetten der Automatisierungstechnik und
                    können sich über Trends und neue Ideen informieren.
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        </Carousel>
      </div>
    </React.Fragment>
  );
}

export default Homeslider;