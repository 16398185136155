import React from 'react';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';

function PreiseKonditionen() {

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="text-center pb-3">Preise &amp; Konditionen (2025)</h1>
          <p className="lead pb-4">
            Basis für eine Teilnahme bei den Fachpressetagen ist immer die Networking-Teilnahme.
            Pressegesprächs-Tisch und Multi-Pressekonferenz lassen sich auch miteinander kombinieren;
            die Kosten für die Networking-Teilnahme fallen dann nur einmal an.</p>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={3} className="g-4">
        <Col>
          <Card variant='light' text='dark' className='h-100'>
            <Card.Header className="d-flex text-center">
              <h3 className="mx-auto flex-grow-1 py-3 my-0 mb-1">Grundpaket<br /> Networking-Teilnahme</h3>
            </Card.Header>
            <Card.Title className="d-flex text-center mb-0">
              <h1 className="mx-auto flex-grow-1 py-4 m-0">650 €</h1>
            </Card.Title>
            <Card.Body className='p-0 m-0'>
              <Table striped className='m-0 mb-1'>
                <tbody>
                  <tr>
                    <td>Teilnahme einer Person an Fachpressetagen (inkl. aller Mahlzeiten und Pausengetränke)</td>
                  </tr>
                  <tr>
                    <td>Teilnahme einer Person am Meet-the-Press-Abend</td>
                  </tr>
                  {/* 
                  <tr>
                    <td>Teilnahme einer Person an der Abendveranstaltung "Fachpressetage zu Gast bei ..."</td>
                  </tr>
                  */}
                  <tr>
                    <td>Nutzung des Online-Portals portal.fachpressetage.de (Profilseite anlegen, Kontakte knüpfen, Texte hochladen u.v.m.)</td>
                  </tr>
                  <tr>
                    <td>Möglichkeit, Pressemappen an anwesende Journalistinnen und Journalisten zu verteilen</td>
                  </tr>
                  <tr>
                    <td>Einladung der Journalistinnen und Journalisten durch den Veranstalter</td>
                  </tr>
                  <tr>
                    <td><strong>Weitere Teilnehmer:innen desselben Unternehmens kosten pauschal 280&nbsp;€ pro Person</strong></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              <i>Sie möchten die Veranstaltung ohne Tisch und Vortrag kennenlernen?</i>
              <Button href="mailto:kontak@rbsonline.de?subject=Ich%20m%C3%B6chte%20nur%20mit%20einer%20Networking-Teilnahme%20an%20den%20FPT%20teilnehmen" variant='rbs-button-orange-inverse' size='lg' className='px-5 my-2'>Sprechen Sie uns an</Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card variant='light' text='dark' className='h-100'>
            <Card.Header className="text-center">
              <h3 className="py-3 pt-4 my-0 mb-1">Pressegesprächs-Tisch *</h3>
            </Card.Header>
            <Card.Title className="d-flex text-center mb-0">
              <h1 className="mx-auto flex-grow-1 py-4 m-0">2.250 €</h1>
            </Card.Title>
            <Card.Body className='p-0 m-0'>
              <Table striped className='m-0 mb-1'>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: `#333`, color: `#fff` }}><h5>* Voraussetzung:</h5>
                      Grundpaket "Networking-Teilnahme"</td>
                  </tr>
                  <tr>
                    <td>
                      2 Tage Messetisch im zentralen Messesaal (lernen Sie Journalistinnen und Journalisten kennen, pflegen
                      Sie Kontakte, stellen Sie Ihre Neuheiten vor, …) inkl. Pinnwand, Strom und WLAN
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Platz für z.B. Marketing-Mitarbeiter:in und Produkt-Manager:in;
                      wir weisen bei der Einladung die Journalistinnen und
                      Journalisten auf den Tisch und das Schwerpunktthema hin
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Hinweis: Bei der Verteilung der begrenzten Plätze werden Unternehmen bevorzugt, die einen
                      Pressegesprächs-Tisch, aber keinen Fachvortrag gebucht haben
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              <Button href="#Tisch" variant='rbs-button-orange' size='lg' className='px-5 my-2'>Jetzt buchen</Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Card variant='light' text='dark' className='h-100'>
            <Card.Header className="text-center px-0">
              <h3 className="py-3 my-0 mb-1">Fachvortrag bei<br />
                Multi-Pressekonferenz *</h3>
            </Card.Header>
            <Card.Title className="d-flex text-center mb-0">
              <h1 className="mx-auto flex-grow-1 py-4 m-0">2.480 €</h1>
            </Card.Title>
            <Card.Body className='p-0 m-0'>
              <Table striped className='m-0 mb-1'>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: `#333`, color: `#fff` }}><h5>* Voraussetzung:</h5>
                      Grundpaket "Networking-Teilnahme"</td>
                  </tr>
                  <tr>
                    <td>Präsentieren Sie in einem Fachvortrag Ihre technischen Neuheiten einem großen Fachpressepublikum</td>
                  </tr>
                  <tr>
                    <td>Eingeladene Journalist:innen entscheiden in einer Online-Abstimmung über Teilnahme</td>
                  </tr>
                  <tr>
                    <td>Vortragsdauer: ca. 25 min inkl. kurzer Diskussion; Redaktionen werden gezielt vom rbs zu passenden Vorträgen eingeladen</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              <Button href="#Vortrag" variant='rbs-button-orange' size='lg' className='px-5 my-2'>Jetzt buchen</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className='pt-3'>Bitte beachten Sie unsere allgemeinen Geschäftsbedingungen: <a href="/AGB">AGB</a></p>
        </Col>
      </Row>
    </Container>
  );
}

export default PreiseKonditionen;