export const settings = {
    network: {
        baseUrl: 'https://meinrbs.rbsonline.de',
        //baseUrl: 'https://localhost:8001',    
        logoUrl: 'https://fptfiles.rbsonline.de/logos/',
    },
    style: {
        breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400 }
    },
    currentFpt: 'fpt035',
    fpt: {
        fpt: { year: 0, title: 'Alle Fachpressetage' },
        fpt031: { year: 2020, title: 'Fachpressetage 2020' },
        fpt032: { year: 2021, title: 'Fachpressetage 2021' },
        fpt033: { year: 2022, title: 'Fachpressetage 2022' },
        fpt034: { year: 2023, title: 'Fachpressetage 2023' },
        fpt035: { year: 2024, title: 'Fachpressetage 2024' },
        fpt036: { year: 2025, title: 'Fachpressetage 2025' },
    },
    debugDelay: false // 3000 = 3 seconds
};