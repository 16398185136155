import React from "react";
import {Nav} from 'react-bootstrap';
import ScrollspyNavItemHome from "./ScrollspyNavItemHome";

function SubNavigation(props) {
    return (        
        <Nav variant="pills" defaultActiveKey={props.defaultActiveKey}>
            <ScrollspyNavItemHome/>
            <Nav.Item>
              <Nav.Link href="Kontakt">Kontakt</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="Impressum">Impressum</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="Agb">AGB</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="Datenschutz">Datenschutz</Nav.Link>
            </Nav.Item>        
        </Nav>
    )
  }
  
  export default SubNavigation;