import React, { useState } from 'react';
import { Container, Row, Col, Button, Tab, Nav, Dropdown } from 'react-bootstrap';
import { ReactComponent as ImageFiletypePdf } from '../../../images/filetype-pdf.svg';

import Programmraster2020 from '../2020/Programmraster2020';
import Programmraster2021 from '../2021/Programmraster2021';
import Programmraster2022 from '../2022/Programmraster2022';
import Programmraster2023 from '../2023/Programmraster2023';
import Programmraster2024 from '../2024/Programmraster2024';
import { settings } from '../../../config/settings';


function Programm() {

  const [selectedFpt, setSelectedFpt] = useState('fpt035');

  const onTabChange = (key) => {
    setSelectedFpt(key);
  }

  return (
    <React.Fragment>
      <Container>
        <Tab.Container onSelect={onTabChange} activeKey={selectedFpt}>
          <Row className='pt-5 justify-content-center'>

            <Col className='text-end'>
              <h1 className="pt-2 pb-5 mb-2">Programm</h1>
            </Col>

            <Col>
              <Nav variant="pills" className='d-none d-lg-block RbsNavPills'>
                <Nav.Item>
                  <Nav.Link eventKey="fpt032">2021</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fpt033">2022</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fpt034">2023</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fpt035">2024</Nav.Link>
                </Nav.Item>                
              </Nav>

              <Dropdown onSelect={onTabChange} className='d-lg-none RbsDropdown'>
                <Dropdown.Toggle className='btn-rbs-button-orange-inverse'>
                  {settings.fpt[selectedFpt].year}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="fpt032">2021</Dropdown.Item>
                  <Dropdown.Item eventKey="fpt033">2022</Dropdown.Item>
                  <Dropdown.Item eventKey="fpt034">2023</Dropdown.Item>
                  <Dropdown.Item eventKey="fpt035">2024</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="fpt031">
                  <Programmraster2020 />
                  <Row>
                    <Col className='text-center'>
                      <Button variant='rbs-button-orange' className='px-4' size='lg' href=""><ImageFiletypePdf className="ImageFiletypePdf" /> Download Pdf</Button>
                    </Col>
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="fpt032">
                  <Programmraster2021 />
                </Tab.Pane>

                <Tab.Pane eventKey="fpt033">
                  <Programmraster2022 />                
                </Tab.Pane>

                <Tab.Pane eventKey="fpt034">
                  <Programmraster2023 />                  
                </Tab.Pane>

                <Tab.Pane eventKey="fpt035">
                  <Programmraster2024 />                  
                </Tab.Pane>

              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </React.Fragment>
  );
}

export default Programm;