import React from "react";
import { Container,Row,Col } from 'react-bootstrap';
import SubNavigation from '../layout/SubNavigation';

const Impressum = () => {
  
  return (
    <React.Fragment>
      <nav className="navbar sticky-top subnav">          
        <Container>            
            <SubNavigation defaultActiveKey="Impressum" />
        </Container> 
      </nav>
      <section className="custom-section-grey">
        <Container>            
            <Row>
                <Col>
                    <h1 className="pb-4">Impressum</h1>
                    <p>
                        rbs Redaktionsbüro Stutensee GbR
                        <br/> Am Hasenbiel 13-15
                        <br/> 76297 Stutensee
                    </p>
                    <p>
                        Telefon: +49 7244 73969-0
                        <br/> Telefax: +49 7244 73969-9
                    </p>
                    <p>
                        E-Mail:
                        <a href="mailto:kontakt@rbsonline.de">kontakt@rbsonline.de</a>
                        <br/> Internet:
                        <a href="http://www.rbsonline.de">www.rbsonline.de</a>
                    </p>
                    <p>
                        rbs Redaktionsbüro Stutensee wird vertreten durch: Alexander Homburg, Ellen-Christine Reiff und Dietrich Homburg (Anschrift
                        wie oben)
                    </p>


                    <p>
                        <b>Berufshaftpflichtversicherung:</b>
                        Markel International Insurance Company Ltd., Sophienstr. 26, 80333 München
                        <br/> Räumlicher Geltungsbereich: weltweit mit Ausnahmen für USA und Kanada
                        <br/>
                    </p>
                    <p>
                        <strong>Umsatzsteuer-Identifikationsnummer</strong> gemäß § 27a Umsatzsteuergesetz (UStG): DE227239379
                    </p>

                    <p>
                        Inhaltlich Verantwortlicher gemäß § 55 Rundfunkstaatsvertrag (RStV): Alexander Homburg
                    </p>

                    <p>
                        Dieses Impressum wurde erstellt und wird laufend aktualisiert durch die <a href="https://www.datenschutz-janolaw.de/">janolaw AG</a>.
                    </p>

                </Col>
            </Row>
        </Container> 
    </section>   
    </React.Fragment>      
  )

}

export default Impressum;
