import React from 'react';
import ScrollspyNav from "react-scrollspy-nav";
import { Container, Nav } from 'react-bootstrap';
import ScrollspyNavItemHome from "../layout/ScrollspyNavItemHome";
import SubNavigationTeaserButton from "../layout/SubNavigationTeaserButton";

import Programm2023 from "../sections/2023/Programm2023";
import Firmenteilnehmer2023 from "../sections/2023/Firmenteilnehmer2023";
import Redaktionen from "../sections/Rueckblick/Redaktionen";
import Firmenbesuch from "../sections/Firmenbesuch";
import InfosDownloads from "../sections/InfosDownloads";
import RbsPage from '../controls/RbsPage';

const Fachpressetage2023 = () => {

  return (
    <RbsPage>

      <nav className="navbar sticky-md-top subnav">
        <Container>
          <ScrollspyNav scrollTargetIds={["Programm", "Firmenteilnehmer", "Redaktionen", "InfosDownloads", "Firmenbesuch"]} offset={0} activeNavClass="active" scrollDuration="10" headerBackground="true">
            <Nav variant="pills">
              <ScrollspyNavItemHome />
              <Nav.Item>
                <Nav.Link href="#Programm">Programm</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Firmenteilnehmer">Firmenteilnehmer</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Redaktionen">Redaktionen</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Firmenbesuch">Firmenbesuch</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#InfosDownloads">Infos &amp; Downloads</Nav.Link>
              </Nav.Item>
            </Nav>
          </ScrollspyNav>
        </Container>
        <SubNavigationTeaserButton />
      </nav>
      <section id="Programm" className="custom-section-grey scrollIntoViewFix"><Programm2023 /></section>
      <section id="Firmenteilnehmer"><Firmenteilnehmer2023 /></section>
      <section id="Redaktionen"><Redaktionen /></section>
      <section id="Firmenbesuch" className="custom-section-grey"><Firmenbesuch /></section>
      <section id="InfosDownloads" className="custom-section-grey"><InfosDownloads /></section>

    </RbsPage>
  )

}

export default Fachpressetage2023;
