import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import ImageThemen from '../../images/home/1920x466.png'

function Themen() {
    return (
      <React.Fragment>
        <img src={ImageThemen} className='img-fluid rbs-themen-bild' alt='Themen'/>
        <Container>
        <Row>
          <Col className='text-center'>
            <h1 className="pb-2">Themen: Die ganze Welt der Automatisierung</h1>
            <p className="lead pb-4">Fachpressetage geben einen Überblick über verschiedene Facetten der gesamten Automatisierungstechnik.</p>
          </Col>
        </Row>
          <Row xs={1} sm={2} lg={3} className="g-4">
              <Col>
                <Card>
                  <Card.Body>
                  <h4>Produktion</h4>
                    <ul>
                        <li>Fertigungssteuerung</li>
                        <li>Prozessautomatisierung</li>
                        <li>Produktionsmanagement</li>
                        <li>Prozessmanagement</li>
                        <li>MES und ERP</li>
                        <li>Fabrik 4.0</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <h4>Bedienen und Beobachten</h4>
                      <ul>
                          <li>SCADA</li>
                          <li>Visualisierung</li>
                          <li>HMI</li>
                          <li>Gebäudeautomation</li>
                      </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <h4>Sensorik</h4>
                    <ul>
                      <li>Visionsensoren</li>
                      <li>Lichtschranken</li>
                      <li>Näherungschalter</li>
                      <li>Weg- und Winkelsensorik</li>
                      <li>Temperatur, Druck, Durchfluss</li>
                  </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                  <h4>Mess- und Prüftechnik</h4>
                    <ul>
                        <li>Messwerterfassung</li>
                        <li>Messdatenmanagement</li>
                        <li>Prozessmesstechnik</li>
                        <li>Oberflächenmesstechnik</li>
                        <li>Wägetechnik</li>
                        <li>Test- und Prüfeinrichtungen</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                  <h4>Sicherheitstechnik</h4>
                    <ul>
                        <li>Ex-Schutz</li>
                        <li>Personenschutz</li>
                        <li>Datensicherheit</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <h4>Kommunikation</h4>
                    <ul>
                        <li>Bustechnik</li>
                        <li>Datenfunk / Wireless</li>
                        <li>Informationsmanagement</li>
                        <li>Gebäude-Kommunikationstechnik</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                  <h4>Steuern</h4>
                    <ul>
                        <li>SPS, IPC, CNC</li>
                        <li>Embedded Systeme</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                  <h4>Aktorik</h4>
                    <ul>
                        <li>elektrische Antriebstechnik</li>
                        <li>Antriebssteuerung</li>
                        <li>Hydraulik</li>
                        <li>Pneumatik</li>
                        <li>Kupplung, Bremse, Getriebe</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <h4>Komponenten</h4>
                    <ul>
                        <li>Lüfter, Kühlung</li>
                        <li>Gehäusetechnik</li>
                        <li>Eingabeelement</li>
                        <li>Netzteile</li>
                        <li>USV</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
          </Row>        
      </Container>
      </React.Fragment>
      
    );
  }
  
  export default Themen;