import React from 'react';
import { Col, Row, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaqItem from '../../controls/FaqItem';


function FaqVortrag(props) {

    const linkTo = (id) => {
        props.onLink && props.onLink(id);
    }

    return (
        <FaqItem
            id="FaqMaterialVortrag"
            header="Material zum Vortrag bei der Multi-Pressekonferenz"
            visible={props.visible}
            class='rbs-vortrag-header'
        >
            <Row>
                <Col xs={12} lg={6} className='pe-xl-4'>
                    <ul>
                        <li>
                            <b>Pressetext zum Vortragsthema</b>: Stellen Sie den anwesenden Journalist:innen einen druckfähigen Text zu Ihrem Vortrags&shy;thema bereit. Damit erhöhen Sie Ihre Veröffentlichungschancen in der Fachpresse.
                            (<Link onClick={() => linkTo("FaqTextVomRBS")}>Wir können Sie dabei gerne unterstützen</Link>)</li>
                        <li><b>Handout der Präsentationsfolien:</b> Idealerweise bieten diese ausreichend Platz für Notizen, dann können sich
                            Journalist:innen während des Vortrags Notizen machen.</li>
                        <li><b>Optional Werbegeschenke</b></li>
                    </ul>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <Alert>
                        <strong>Hinweis:</strong> Legen Sie alles Material VOR Ihrem Vortrag an die Plätze der Journalist:innen oder in deren Presseboxen.
                    </Alert>
                    <h5>Hier finden Sie weite Infos zu :</h5>
                    <p>
                        <Button className='btn-rbs-button-orange-inverse mb-2' size='md' onClick={() => linkTo("FaqInhaltPresemappe")}>Pressematerial</Button><br/>
                        <Button className='btn-rbs-button-orange-inverse mb-2' size='md'  onClick={() => linkTo("FaqWerbegeschenke")}>Werbegeschenke</Button><br/>
                        <Button className='btn-rbs-button-orange-inverse mb-2' size='md' onClick={() => linkTo("FaqUploadPortal")}>Upload ins Portal</Button>
                    </p>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;



