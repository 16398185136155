import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqRahmenbedingungenTisch"
            header="Rahmenbedingungen Pressegesprächs-Tische"
            visible={props.visible}
            class='rbs-tisch-header'
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-3'>
                    <ul>
                        <li>
                            Teilnehmende Firmen erhalten einen <strong>Tisch (180 cm x 80 cm)</strong>,
                            gekennzeichnet mit Firmennamen, zwei bis vier Stühle (beispielsweise auch
                            für einen Spezialisten aus Ihrem Unternehmen oder für Ihre Presseagentur)
                        </li>
                        <li>
                            <strong>Stromanschluss</strong> (230V) und <strong>W-LAN</strong> sind vorhanden.
                        </li>
                    </ul>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <ul>
                        <li>
                            Bereich können Sie nach Belieben gestalten: Platz mit Firmenlogo kennzeichnen,
                            Roll-Up Banner aufstellen, Notebook mit
                            Präsentation, (kleinformatige) Produkte zur näheren Erläuterung, Pressemappen, u.v.m.
                        </li>
                        <li>
                            Falls gewünscht: <strong>Stellwände/Moderationstafeln (ca. 150 x 120 cm)</strong> zum
                            Anheften z.B. von Postern mit Stecknadeln
                        </li>
                    </ul>
                </Col>
            </Row>
        </FaqItem>
    );
}

export default FaqVortrag;