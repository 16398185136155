import React from "react";
import { Container, Navbar, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ImageFptLogo } from '../../images/fpt-logo.svg';
import { LinkContainer } from "react-router-bootstrap";

const Navigation = () => {

  const location = useLocation();

  return (
    <Navbar bg="light" expand="lg" id="Top">
      <Container>
        <Navbar.Brand href="/"><ImageFptLogo className="ImageFptLogo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav activeKey={location.pathname}>
            <LinkContainer to="/"><Nav.Link eventKey="/">Home</Nav.Link></LinkContainer>
            <LinkContainer to="/Fachpressetage2025"><Nav.Link eventKey="/Fachpressetage2025">2025</Nav.Link></LinkContainer>
            <LinkContainer to="/Rueckblick"><Nav.Link eventKey="/Rueckblick">Rückblick</Nav.Link></LinkContainer>
            <LinkContainer to="/Anmeldung"><Nav.Link eventKey="/Anmeldung">Anmeldung</Nav.Link></LinkContainer>
            <LinkContainer to="/FAQ"><Nav.Link eventKey="/FAQ">FAQ</Nav.Link></LinkContainer>
            <Nav.Link href="https://portal.fachpresse.live" target="_blank">Portal</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation;