import React from 'react';

import FaqItem from '../../controls/FaqItem';
import { Col, Row } from 'react-bootstrap';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FqaZiel"
            header="Ziel der Fachpressetage"
            visible={props.visible}
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <p>Die Fachpressetage verfolgen im Wesentlichen zwei Ziele:</p>
                    <p>
                        <ul>
                            <li><strong>Kontaktpflege</strong> zwischen Firmen und Fachredaktionen </li>
                            <li><strong>Kommunikation aktueller Themen</strong> über die Fachpresse </li>
                        </ul>
                    </p>
                    <p>
                        Tipps, wie das gelingen kann finden Sie auch in weiteren FAQs
                    </p>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;