import React from 'react';

import { settings } from '../../../config/settings';
import FaqItem from '../../controls/FaqItem';
import { Col, Row } from 'react-bootstrap';


function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqAnwesenheitPortal"
            header="Anwesenheit im Portal vermerken"
            visible={props.visible}
        >

            <Row className="gx-lg-4 gx-xl-5">
                <Col className="col-12 col-lg-6 mb-6">
                    <p>
                        Damit Firmenteilnehmer:innen sich auf die Fachpressetage vor&shy;be&shy;reiten können, ist es hilfreich, wenn diese
                        wissen, wann welche Redakteur:innen anwesend sind. Vermerken Sie daher
                        bitte <a target='_blank' href={"https://portal.fachpresse.live/" + settings.currentFpt + "/werWann/"}>im Portal</a>,
                        an welchen Tagen und zu welchen Veranstaltungsblöcken Sie bei der Veranstaltung sein werden. Das hilft uns
                        zudem bei der Planung.
                    </p>
                </Col>
            </Row>


        </FaqItem>
    );
}

export default FaqVortrag;