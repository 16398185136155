const defaultJahr = {
    firmen: undefined,
    redaktionen: undefined,
    programm: undefined,
}

const fptReducerDefaultState = {
    currentFpt: 'fpt035',
    stimmen: undefined,
    vortraege: [],
    // für jedes Jahr ein Objekt mit firmen und redaktionen
    fpt036: defaultJahr,
    fpt035: defaultJahr,
    fpt034: defaultJahr,
    fpt033: defaultJahr,
    fpt032: defaultJahr,
    fpt031: defaultJahr,
    fpt: defaultJahr, // = alle Jahre!
};


const fptReducer = (state = fptReducerDefaultState, action) => {

    switch (action.type) {
        case 'SET_FIRMENTEILNEHMER':
            return {
                ...state,
                [action.idfpt]: {
                    ...state[action.idfpt],
                    firmen: action.firmen
                }
            }
        case 'SET_REDAKTIONEN':
            return {
                ...state,
                [action.idfpt]: {
                    ...state[action.idfpt],
                    redaktionen: action.firmen
                }
            }
        case 'SET_PROGRAMM':
            return {
                ...state,
                [action.idfpt]: {
                    ...state[action.idfpt],
                    programm: {
                        ...state[action.idfpt].programm,
                        [action.tag]: action.programm
                    }
                }
            }
        case 'SET_STIMMEN':
            return {
                ...state,
                stimmen: action.stimmen
            }
        case 'ADD_VORTRAG':
            return {
                ...state,
                vortraege: [
                    ...state.vortraege,
                    action.vortrag
                ]
            }
        default:
            return state;
    }
}

export default fptReducer;