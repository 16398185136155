import React from 'react';
import ScrollspyNav from "react-scrollspy-nav";
import { Container, Nav } from 'react-bootstrap';
import ScrollspyNavItemHome from "../layout/ScrollspyNavItemHome";
import SubNavigationTeaserButton from "../layout/SubNavigationTeaserButton";

import Redaktionen2024 from "../sections/2024/Redaktionen2024";
import Firmenbesuch from "../sections/Firmenbesuch";
import InfosDownloads from "../sections/InfosDownloads";
import RbsPage from '../controls/RbsPage';
import Programm2024 from '../sections/2024/Programm2024';
import Firmen2024 from '../sections/2024/Firmen2024';
import Faq_Section from '../sections/Faq_Section';

const Fachpressetage2024 = (props) => {

  return (
    <RbsPage>

      <nav className="navbar sticky-md-top subnav">
        <Container>
          <ScrollspyNav scrollTargetIds={[
            "Programm",
            "Firmenteilnehmer",
            //"Redaktionen",
            "InfosDownloads",
            //"Firmenbesuch",
            "Faq"
          ]} offset={0} activeNavClass="active" scrollDuration="10" headerBackground="true">
            <Nav variant="pills">
              <ScrollspyNavItemHome />
              <Nav.Item>
                <Nav.Link href="#Programm">Programm</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Firmenteilnehmer">Firmenteilnehmer</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link href="#Redaktionen">Redaktionen</Nav.Link>
              </Nav.Item>
{/* 
              <Nav.Item>
                <Nav.Link href="#Firmenbesuch">Firmenbesuch</Nav.Link>
              </Nav.Item>
*/}
              <Nav.Item>
                <Nav.Link href="#InfosDownloads">Infos &amp; Downloads</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Faq">FAQ</Nav.Link>
              </Nav.Item>
            </Nav>
          </ScrollspyNav>
        </Container>
        <SubNavigationTeaserButton />
      </nav>
      <section id="Programm" className="custom-section-grey scrollIntoViewFix"><Programm2024 /></section>
      <section id="Firmenteilnehmer"><Firmen2024 /></section>

      <section id="Redaktionen"><Redaktionen2024/></section>
      {/* 
  <section id="Firmenbesuch" className="custom-section-grey"><Firmenbesuch/></section>*/}

      <section id="InfosDownloads" className="custom-section-grey"><InfosDownloads /></section>
      <section id="Faq"><Faq_Section teilnehmerTyp={props.tlnTyp} /></section>


    </RbsPage>
  )

}

export default Fachpressetage2024;
