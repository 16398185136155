import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import globalReducer from "./reducers/globalReducer";
import fptReducer from "./reducers/fptReducer";

//thunk middleware is used to intercept actions so as to make API call before dispatching result to reducer
const store = createStore(
    combineReducers({
        global: globalReducer,
        fpt: fptReducer
    }),
    applyMiddleware(thunk)
);

export default store;