import React from 'react';

import FaqItem from '../../controls/FaqItem';
import { Col, Row } from 'react-bootstrap';


function FaqVorabend(props) {

    return (
        <FaqItem
            id="FaqVorabend"
            header="Kann ich schon am Vorabend anreisen?"
            visible={props.visible}
        >

            <Row className="gx-lg-4 gx-xl-5">
                <Col className="col-12 col-lg-6 mb-6">
                    <p>
                       Ja!
                    </p>
                    <p>
                        Für manche ist es genütlicher schon am Vorabend anzureisen. Das können Sie gerne tun. 
                        Wir übernehmen auch dafür die Hotelkosten.
                    </p>
                </Col>
            </Row>


        </FaqItem>
    );
}

export default FaqVorabend;