import React, { Fragment, useEffect } from "react"
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadProgramm } from "../../../store/actions/fpt";
import FptProgrammItem from "../../controls/FptProgrammItem";

const ProgrammRaster2021 = (props) => {

    //useSelector(state => console.log(state.fpt.fpt032.programm && state.fpt.fpt032.programm.tag1));

    const dispatch = useDispatch();
    const programm = useSelector(state => state.fpt.fpt032.programm);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dispatch(loadProgramm('fpt032', 'tag1', 'fpt032_tag1.xml'));
        dispatch(loadProgramm('fpt032', 'tag2', 'fpt032_tag2.xml'));
        dispatch(loadProgramm('fpt032', 'tag3', 'fpt032_tag3.xml'));
    }, []);


    //#region FormEvents

    return (
        <Fragment>
            <Row className='pb-4 pb-lg-0'>
                <Col xs={12} md={6} lg={4} className='pb-4'>
                    <div className="d-flex flex-column programm-wrapper">
                        <div className="programm-white shadow-sm">
                            <strong>Montag</strong> 01.02.2021
                        </div>
                        <div className="programm-white shadow-sm">
                            <Table borderless>
                                <tbody>
                                    {programm && programm.tag1 && programm.tag1.map((item, index) =>
                                        <FptProgrammItem key={index} idFpt={'fpt032'} data={item} />
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={4} className='pb-4'>
                    <div className="d-flex flex-column programm-wrapper">
                        <div className="programm-white shadow-sm">
                            <strong>Dienstag</strong> 02.02.2021
                        </div>
                        <div className="programm-white shadow-sm">
                            <Table borderless>
                                <tbody>
                                    {programm && programm.tag2 && programm.tag2.map((item, index) =>
                                        <FptProgrammItem key={index} idFpt={'fpt032'} data={item} />
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <div className="d-flex flex-column programm-wrapper">
                        <div className="programm-white shadow-sm">
                            <strong>Dienstag</strong> 09.02.2021
                        </div>
                        <div className="programm-white shadow-sm">
                            <Table borderless>
                                <tbody>
                                    {programm && programm.tag3 && programm.tag3.map((item, index) =>
                                        <FptProgrammItem key={index} idFpt={'fpt032'} data={item} />
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>

        </Fragment>

    )

}
export default ProgrammRaster2021;
