import React, { useEffect } from 'react';
import ScrollspyNav from "react-scrollspy-nav";
import { Container, Nav } from 'react-bootstrap';
import ScrollspyNavItemHome from "../layout/ScrollspyNavItemHome";
import SubNavigationTeaserButton from "../layout/SubNavigationTeaserButton";

import InfosDownloads from "../sections/InfosDownloads";
import RbsPage from '../controls/RbsPage';
import Faq_Section from '../sections/Faq_Section';
import { useLocation } from 'react-router-dom';


const FAQ = () => {

  const location = useLocation();
  const [tlnTyp, setTlnTyp] = React.useState([]);

  useEffect(() => {
    if (!location.search) return;
    const searchParams = new URLSearchParams(location.search);
    const typ = searchParams.get('typ');
    setTlnTyp(typ.split(','));
  }, []);




  return (
    <RbsPage>

      <nav className="navbar sticky-md-top subnav">
        <Container>
          <ScrollspyNav scrollTargetIds={["Faq", "InfosDownloads"]} offset={0} activeNavClass="active" scrollDuration="10" headerBackground="true">
            <Nav variant="pills">
              <ScrollspyNavItemHome />
              <Nav.Item>
                <Nav.Link href="#Faq">FAQ</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#InfosDownloads">Infos &amp; Downloads</Nav.Link>
              </Nav.Item>
            </Nav>
          </ScrollspyNav>
        </Container>
        <SubNavigationTeaserButton />
      </nav>

      <section id="Faq" className="scrollIntoViewFix"><Faq_Section teilnehmerTyp={tlnTyp} /></section>
      <section id="InfosDownloads" className="custom-section-grey"><InfosDownloads /></section>

    </RbsPage>
  )

}

export default FAQ;
