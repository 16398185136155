import React from 'react';
import {Container, Row, Col, Carousel, Button} from 'react-bootstrap';
const ImagesKontaktmesse = [
  require('../../images/home/kontaktmesse-01.jpg'),
  require('../../images/home/kontaktmesse-02.jpg'),
  require('../../images/home/kontaktmesse-03.jpg'),
  require('../../images/home/kontaktmesse-04.jpg'),
  require('../../images/home/kontaktmesse-05.jpg'),
  require('../../images/home/kontaktmesse-06.jpg'),
  require('../../images/home/kontaktmesse-07.jpg'),
  require('../../images/home/kontaktmesse-08.jpg'),
  require('../../images/home/kontaktmesse-09.jpg'),
  require('../../images/home/kontaktmesse-10.jpg'),
  require('../../images/home/kontaktmesse-11.jpg'),
  require('../../images/home/kontaktmesse-12.jpg')
];

function Kontaktmesse() {
    return (
      <React.Fragment>
        <Carousel fade controls={false} indicators={false} interval={8000} pause={false}>
          {ImagesKontaktmesse.map(img => (
            <Carousel.Item key={img}>
              <img src={img} alt="Kontaktmesse" className="img-fluid"/>
            </Carousel.Item>
          ))}
        </Carousel> 
        <Container>
          <Row>
            <Col className='text-center'>
              <h1 className="pb-4">Pressegespräche</h1>
            </Col>
          </Row>
          <Row className="gx-lg-4 gx-xl-5 pb-4">
            <Col className="col-12 col-lg-6">
              <p><i>Was ist für Pressverantwortliche die beste Methode, um die für sie wichtigen Redakteurinnen und Redakteure persönlich kennenzulernen?</i><br/><br/>
              
              Gut gemachte Pressekonferenzen im eigenen Haus sind relativ aufwendig und damit teuer. Bei Fachmessen bleibt oft wenig Zeit für Gespräche.</p>
            </Col>
            <Col className="col-12 col-lg-6">
              <p>Ideal ist eine Gesprächsmöglichkeit, bei der sich beide Seiten gezielt Zeit nehmen, einander kennenzulernen und eine konkrete Zusammenarbeit zu besprechen. Hier können Journalisten und Journalistinnen Firmen "besuchen", sich über Neuheiten informieren und Pressemeldungen, Fachaufsätze, Interviews usw. vereinbaren.</p>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col className='text-center'>
              <Button href="/Anmeldung#PreiseKonditionen" variant='rbs-button-orange me-lg-4 mb-4 mb-lg-0 rbs-action-buttons' size='lg'>Preise &amp; Konditionen</Button>
              <br className='d-block d-lg-none'/>
              <Button href="/Anmeldung#Tisch" variant='rbs-button-orange rbs-action-buttons' size='lg'>Pressegesprächs-Tisch buchen</Button>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col className='text-center'>
            <Button variant='rbs-button-orange-inverse rbs-action-buttons' size='lg' href="#Teilnahme">Alle Teilnahme-Möglichkeiten</Button>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
  
  export default Kontaktmesse;