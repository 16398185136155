import React from 'react';
import FaqItem from '../../controls/FaqItem';
import { Col, Row } from 'react-bootstrap';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqZeitschriftenAuslegen"
            header="Zeitschriften auslegen"
            visible={props.visible}
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <p>Gerne können Sie <strong>kostenlose Exemplare ihrer Zeitschriften am Veranstaltungsort auslegen</strong>. Erfahrungsgemäß reicht es,
                        wenn Sie ca. 25 Exemplare mitbringen.
                    </p>
                    <p>
                        Sie können Zeitschriften auch vorab ans Veranstaltungshotel schicken:
                    </p>
                    <p>
                        GenoHotel Karlsruhe <br />
                        Stichwort: "Fachpressetage" <br />
                        Am Rüppurrer Schloß 40 <br />
                        76199 Karlsruhe <br />
                    </p>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;