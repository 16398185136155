import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaqItem from '../../controls/FaqItem';


function FaqVortrag(props) {

    const linkTo = (id) => {
        props.onLink && props.onLink(id);
    }

    return (
        <FaqItem
            id="FaqMaterialTisch"
            header="Material für Redakteure"
            visible={props.visible}
            class='rbs-tisch-header'
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-4'>
                    <ul>
                        <li> <b>Pressetext zum Schwerpunktthema:</b> Stellen Sie den an&shy;we&shy;sen&shy;den Journalisten einen druckfähigen Text zu Ihrem Schwer&shy;punkt&shy;thema bereit.
                            Damit erhöhen Sie Ihre Ver&shy;öffentlichungs&shy;chancen in der Fachpresse.
                            (<Link onClick={() => linkTo("FaqTextVomRBS")}>Siehe auch Pressetext zum Vortrags- oder Schwerpunktthema vom RBS</Link>)
                        </li>
                        <li><b>Optional Werbegeschenke</b> </li>
                    </ul>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <p>
                        <h5>Weitere Infos zum Thema:</h5>

                        <Button className='btn-rbs-button-orange-inverse mb-2' size='md' onClick={() => linkTo("FaqInhaltPresemappe")}>Inhalt Pressemappe</Button><br />
                        <Button className='btn-rbs-button-orange-inverse mb-2' size='md' onClick={() => linkTo("FaqWerbegeschenke")}>Werbegeschenke</Button><br />
                        <Button className='btn-rbs-button-orange-inverse mb-2' size='md' onClick={() => linkTo("FaqUploadPortal")}>Upload Portal</Button>
                    </p>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;