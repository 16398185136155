import React from 'react';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
const ImagesMultiPressekonferenz = [
  require('../../images/home/multi-pressekonferenz-00.jpg'),
  require('../../images/home/multi-pressekonferenz-01.jpg'),
  require('../../images/home/multi-pressekonferenz-02.jpg'),
  require('../../images/home/multi-pressekonferenz-03.jpg'),
  require('../../images/home/multi-pressekonferenz-04.jpg'),
  //require('../../images/home/multi-pressekonferenz-05.jpg')
];

function MultiPressekonferenz() {
  return (
    <React.Fragment>
      <Carousel fade controls={false} indicators={false} interval={10000} pause={false}>
        {ImagesMultiPressekonferenz.map(img => (
          <Carousel.Item key={img}>
            <img src={img} alt="Multi-Pressekonferenz" className="img-fluid" />
          </Carousel.Item>
        ))}
      </Carousel>
      <Container>
        <Row>
          <Col className='text-center'>
            <h1 className="pb-4">Multi-Pressekonferenz</h1>
          </Col>
        </Row>
        <Row className="gx-lg-4 gx-xl-5 pb-4">
          <Col className="col-12 col-lg-6">
            <p>Brücke zur Presse – Kleine und mittelständische Unternehmen finden meist nur schwer den Zugang zur Fachpresse. Wir vom Redaktionsbüro Stutensee (rbs) haben es uns deshalb zur Aufgabe gemacht, die Brücke zwischen Industrie und Fachpresse zu schlagen, indem wir Firmen aus dem Umfeld der Automatisierungstechnik bei ihren Presseaktivitäten unterstützen.</p>
          </Col>
          <Col className="col-12 col-lg-6">
            <p>Präsentieren Sie neue Ideen, Lösungen, Produkte oder spannende Anwendungen in einem Vortrag oder zeigen Sie in einem Grundlagenvortrag die Kompetenz Ihres Unternehmens.<br /><br />

              Um die Qualität der Beiträge zu sichern, entscheidet eine Jury aus Redakteuren und Redakteurinnen über die Aufnahme ins Programm. Wir beraten Sie gerne, wie Sie ein geeignetes Thema finden.</p>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col className='text-center'>
            <LinkContainer to={{ pathname: "/Anmeldung", hash: "PreiseKonditionen" }}>
              <Button variant='rbs-button-orange me-lg-4 mb-4 mb-lg-0 rbs-action-buttons' size='lg'>Preise &amp; Konditionen</Button>
            </LinkContainer>
            <br className='d-block d-lg-none' />
            <LinkContainer to="/Anmeldung">
              <Button variant='rbs-button-orange rbs-action-buttons' size='lg'>Vortrags-Option anmelden</Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row className="pb-3">
          <Col className='text-center'>
            <Button variant='rbs-button-orange-inverse rbs-action-buttons' size='lg' href="#Teilnahme">Alle Teilnahme-Möglichkeiten</Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default MultiPressekonferenz;