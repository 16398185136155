import React, { useState } from 'react';
import {Container, Row, Col, Card, Button, Stack} from 'react-bootstrap';
import ImageZuGast1 from '../../images/home/2012-rockwell.jpg';
import ImageZuGast2 from '../../images/home/2017-polytec.jpg';
import ImageZuGast3 from '../../images/home/2016-hirschmann_mcs.jpg';
import ImageZuGast4 from '../../images/home/2014-ict.jpg';
import ImageZuGast5 from '../../images/home/2013-pi.jpg';
import ImageZuGast6 from '../../images/home/2010-hima.jpg';

function ZuGast() {

  const [toggle, setToggle] = useState(false)
  const [toggle1, setToggle1] = useState(false)
  const [toggle2, setToggle2] = useState(false)
  const [toggle3, setToggle3] = useState(false)
  const [toggle4, setToggle4] = useState(false)
  const [toggle5, setToggle5] = useState(false)
  
    return (
      <React.Fragment>
        <Container className='mb-5'>
          <Row>
            <Col>
              <h1 className="mb-5 text-center">Fachpressetage zu Gast bei Ihnen</h1>
            </Col>
          </Row>
          <Row className="gx-lg-4 gx-xl-5">
            <Col className="col-12 col-lg-6 mb-4 mb-lg-0">
              <p>Jedes Jahr bieten wir <strong>einem Unternehmen der Region Karlsruhe</strong> die Chance, sich im Rahmen der Fachpressetage in den eigenen Räumen der Fachpresse vorzustellen. Diese Abendveranstaltung bietet eine besondere Gelegenheit, viele Redakteure und Redakteurinnen im eigenen Haus zu empfangen – eine Presseveranstaltung, wie sie sonst nur großen Unternehmen möglich ist. Neben einem Fachvortrag bleibt genügend Zeit für die Vorstellung des Unternehmens und für persönliche Kontakte.</p>
            </Col>
            <Col className="col-12 col-lg-6">
            <h4>Voraussetzungen</h4>
            <ul className='plus'>
              <li>Ihr Unternehmen sollte der Fachpresse eine interessante Technik vorstellen können,</li>
              <li>dies in einer Führung auch anschaulich machen können und</li>
              <li>für diese ungewöhnliche Pressekonferenz Raum für etwa 50 Gäste haben.</li>
            </ul>
            </Col>
          </Row>
        </Container>
        <Container fluid className='pb-5'>
          <Row>
            <Col className="col-12 col-lg-6 col-xl-4">
              <div className={`CardWrapper mx-auto mb-5 ${toggle1 ? "active": ""}`} style={{ backgroundImage:`url(${ImageZuGast1})` }}>
                <Card>
                  <Card.Header onClick={() => setToggle1(!toggle1)}>
                    <Stack direction="horizontal">
                      <div>
                          <h4 className='p-0 m-0'>2018</h4>
                          <p className='p-0 m-0 lead'>Rockwell Automation</p>                 
                      </div>
                      <div className='ms-auto'>
                        <Button variant="link ToggleButton"/>                  
                      </div>
                    </Stack>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                    Rockwell Automation ist ein spe­ziali­sierter Her­stel­ler von Auto­mati­sierungs- und Infor­ma­tions­lö­sun­gen für die in­dustriel­le Pr­oduk­tion. Das Unter­nehmen be­schäf­tigt etwa 22.000 Mit­ar­bei­ter in mehr als 80 Ländern.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>   
                    <Stack direction="horizontal" gap={3}>
                      <div className='ms-auto'>                                         
                        <Button variant="btn btn-rbs-button-orange" href="https://www.flickr.com/photos/140184022@N05/albums/72157691232639701" target="_blank">Bildergallerie</Button>                 
                      </div>
                      <div className='me-auto'>
                        <Button variant="btn btn-rbs-button-orange" href="https://www.rockwellautomation.com" target="_blank">Webseite</Button>      
                      </div>
                    </Stack> 
                  </Card.Footer>
                </Card>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4 pb-xl-3 pb-xxl-4">
              <div className={`CardWrapper mx-auto mb-5 ${toggle ? "active": ""}`} style={{ backgroundImage:`url(${ImageZuGast2})` }} >
                <Card onClick={() => setToggle(!toggle)}>
                  <Card.Header>
                    <Stack direction="horizontal">
                      <div>
                          <h4 className='p-0 m-0'>2017</h4>
                          <p className='p-0 m-0 lead'>Polytec</p>                 
                      </div>
                      <div className='ms-auto'>
                        <Button variant="link ToggleButton"/>                    
                      </div>
                    </Stack>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                    Als innovatives Hochtechnologie-Unternehmen entwickelt, produziert und vertreibt Polytec seit über 50 Jahren laserbasierte Messtechnik-Lösungen für Forschung und Industrie.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>   
                    <Stack direction="horizontal" gap={3}>
                      <div className='ms-auto'>                                         
                        <Button variant="btn btn-rbs-button-orange" href="https://www.flickr.com/photos/140184022@N05/albums/72157680065291655" target="_blank">Bildergallerie</Button>                 
                      </div>
                      <div className='me-auto'>
                        <Button variant="btn btn-rbs-button-orange" href="https://www.polytec.com" target="_blank">Webseite</Button>      
                      </div>
                    </Stack> 
                  </Card.Footer>
                </Card>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4">
              <div className={`CardWrapper mx-auto mb-5 ${toggle2 ? "active": ""}`} style={{ backgroundImage:`url(${ImageZuGast3})` }} >
                <Card onClick={() => setToggle2(!toggle2)}>
                  <Card.Header>
                    <Stack direction="horizontal">
                      <div>
                          <h4 className='p-0 m-0'>2016</h4>
                          <p className='p-0 m-0 lead'>Hirschmann MCS</p>                 
                      </div>
                      <div className='ms-auto'>
                        <Button variant="link ToggleButton"/>                   
                      </div>
                    </Stack>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                    Als Fachspezialist im Bereich zukunfts­sicherer Steuerungslösungen für den weltweiten Markt mobiler Maschinen entwickelt Hirschmann MCS innovative Steuerungen, Sensorik und Systemlösungen für den Einsatz unter rauen Umgebungs­bedingungen.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>   
                    <Stack direction="horizontal" gap={3}>
                      <div className='ms-auto'>                                         
                        <Button variant="btn btn-rbs-button-orange" href="https://flic.kr/s/aHsktKnY2X" target="_blank">Bildergallerie</Button>                 
                      </div>
                      <div className='me-auto'>
                        <Button variant="btn btn-rbs-button-orange" href="https://www.schulergroup.com" target="_blank">Webseite</Button>      
                      </div>
                    </Stack> 
                  </Card.Footer>
                </Card>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4">
              <div className={`CardWrapper mx-auto mb-5 ${toggle3 ? "active": ""}`} style={{ backgroundImage:`url(${ImageZuGast4})` }} >
                <Card onClick={() => setToggle3(!toggle3)}>
                  <Card.Header>
                    <Stack direction="horizontal">
                      <div>
                          <h4 className='p-0 m-0'>2014</h4>
                          <p className='p-0 m-0 lead'>Fraunhofer ICT</p>                 
                      </div>
                      <div className='ms-auto'>
                        <Button variant="link ToggleButton"/>                    
                      </div>
                    </Stack>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                    Die Wissenschaftler am Fraunhofer-Institut für Che­mi­sche Technologie forschen und ent­wickeln in den Kern­kompe­tenzen ener­ge­tische Ma­te­rialien und Sys­teme, ange­wandte Elektro­chemie, Umwelt Engineering und Polymer Engineering.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>   
                  <div className='text-center'>                                         
                    <Button variant="btn btn-rbs-button-orange " href="https://www.ict.fraunhofer.de" target="_blank">Webseite</Button>                 
                  </div>
                  </Card.Footer>
                </Card>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4">
              <div className={`CardWrapper mx-auto mb-5 ${toggle4 ? "active": ""}`} style={{ backgroundImage:`url(${ImageZuGast5})` }} >
                <Card onClick={() => setToggle4(!toggle4)}>
                  <Card.Header>
                    <Stack direction="horizontal">
                      <div>
                          <h4 className='p-0 m-0'>2013</h4>
                          <p className='p-0 m-0 lead'>Physik Instrumente (PI)</p>                 
                      </div>
                      <div className='ms-auto'>
                        <Button variant="link ToggleButton"/>                   
                      </div>
                    </Stack>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                    PI ist weltweit führen­der An­bie­ter von Pro­duk­ten und Sys­temen für die Nano­po­si­tionierung. Die Posi­tionier­systeme werden z.B. in der Halb­leiter­fer­tigung, in der Me­di­zin­technik, in der Bio­tech­nologie, im Anlagen­bau, in der Ober­flächen­mess­technik oder in der Astro­nomie eingesetzt.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>   
                  <div className='text-center'> 
                    <Button variant="btn btn-rbs-button-orange " href="https://www.physikinstrumente.de" target="_blank">Webseite</Button>                 
                  </div>
                  </Card.Footer>
                </Card>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4">
              <div className={`CardWrapper mx-auto mb-5 ${toggle5 ? "active": ""}`} style={{ backgroundImage:`url(${ImageZuGast6})` }} >
                <Card onClick={() => setToggle5(!toggle5)}>
                  <Card.Header>
                    <Stack direction="horizontal">
                      <div>
                          <h4 className='p-0 m-0'>2012</h4>
                          <p className='p-0 m-0 lead'>HIMA</p>                 
                      </div>
                      <div className='ms-auto'>
                        <Button variant="link ToggleButton"/>                    
                      </div>
                    </Stack>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                    HIMA ist ein Spezialist für sicher­heits­gerichtete Auto­mati­sierungs­lö­sun­gen. Über 33.000 Systeme wurden in mehr als 40 Jahren in über 80 Län­dern in­stal­liert und schützen die An­lagen der weltweit größten Unter­nehmen der Öl- und Gas-, der chemi­schen, pharma­zeu­tischen und der Energie erzeugenden Industrie.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>   
                  <div className='text-center'>                                         
                    <Button variant="btn btn-rbs-button-orange " href="https://www.hima.de" target="_blank">Webseite</Button>                 
                  </div>
                  </Card.Footer>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
  
  export default ZuGast;