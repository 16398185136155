import axios from 'axios';
import { setGlobalError, setGlobalSuccess, setLoading } from './global';
import { settings } from '../../config/settings';

export const setFirmenteilnehmer = (idfpt, firmen) => ({
    type: 'SET_FIRMENTEILNEHMER',
    idfpt,
    firmen,
});

export const setRedaktionen = (idfpt, firmen) => ({
    type: 'SET_REDAKTIONEN',
    idfpt,
    firmen,
});

export const setStimmen = (stimmen) => ({
    type: 'SET_STIMMEN',
    stimmen,
});

export const setProgramm = (idfpt, tag, programm) => ({
    type: 'SET_PROGRAMM',
    idfpt,
    tag,
    programm,
});

export const addVortrag = (vortrag) => ({
    type: 'ADD_VORTRAG',
    vortrag,
});


export const loadFirmenteilnehmer = (idFpt) => {
    return (dispatch) => {

        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadTeilnehmer/${idFpt}/fo`;

        axios.get(
            service
        ).then(res => {
            // delay for testing
            settings.debugDelay ?
                setTimeout(() => {
                    dispatch(setFirmenteilnehmer(idFpt, res.data));
                }, settings.debugDelay)
                :
                dispatch(setFirmenteilnehmer(idFpt, res.data));

            dispatch(setLoading(false));
        }).catch((error) => {
            dispatch(setGlobalError({ message: error }));
            dispatch(setLoading(false));
        });
    }
}

export const loadRedaktionen = (idFpt) => {
    return (dispatch) => {

        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadTeilnehmer/${idFpt}/j`;

        axios.get(
            service
        ).then(res => {
            // delay for testing
            settings.debugDelay ?
                setTimeout(() => {
                    dispatch(setRedaktionen(idFpt, res.data));
                }, settings.debugDelay)
                :
                dispatch(setRedaktionen(idFpt, res.data));
            
            dispatch(setLoading(false));
        }).catch((error) => {
            dispatch(setGlobalError({ message: error }));
            dispatch(setLoading(false));
        });
    }
}


export const loadStimmen = (count = 4) => {
    return (dispatch) => {

        dispatch(setLoading(true));
        const service = `/api/Fpt/GetRandomContentNEntries/fpt_stimmen_online/${count}`;

        axios.get(
            service
        ).then(res => {
            // delay for testing
            settings.debugDelay ?
                setTimeout(() => {
                    dispatch(setStimmen(res.data));
                }, settings.debugDelay)
                :
                dispatch(setStimmen(res.data));

            dispatch(setLoading(false));
        }).catch((error) => {
            dispatch(setGlobalError({ message: error }));
            dispatch(setLoading(false));
        });
    }
}


export const loadProgramm = (idFpt, tag, xmlFile) => {
    return (dispatch) => {

        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadProgram/${xmlFile}`;

        axios.get(
            service
        ).then(res => {
            // delay for testing
            settings.debugDelay ?
                setTimeout(() => {
                    dispatch(setProgramm(idFpt, tag, res.data));
                }, settings.debugDelay)
                :
                dispatch(setProgramm(idFpt, tag, res.data));

            dispatch(setLoading(false));
        }).catch((error) => {
            dispatch(setGlobalError({ message: error }));
            dispatch(setLoading(false));
        });
    }
}

export const readAndAddVortrag = (id) => {
    return (dispatch, getState) => {

        dispatch(setLoading(true));
        const service = `/api/Fpt/ReadVortrag/${id}`;

        axios.get(
            service
        ).then(res => {
            // delay for testing
            settings.debugDelay ?
                setTimeout(() => {
                    dispatch(addVortrag(res.data));
                }, settings.debugDelay)
                :
                dispatch(addVortrag(res.data));

            dispatch(setLoading(false));
        }).catch((error) => {
            dispatch(setGlobalError({ message: error }));
            dispatch(setLoading(false));
        });
    }
}



export const anmeldenFachpressetage = (mailAdress, formData, onSuccess) => {
    return (dispatch, getState) => {

        // convert all Entries in formData to string
        const stingFormData = {};
        Object.keys(formData).forEach(function (key) {
            stingFormData[key] = formData[key].toString();
        });

        const daten = {
            mailData: {
                toMailAdress: mailAdress,
                fromMailAdress: "kontakt@rbsonline.de",
                idAutomail: 0,
                ablegen: true
            },
            hash: "1928#toh",
            data: stingFormData
        }

        dispatch(setLoading(true));
        const service = `/api/Kontakt/AnmeldungFachpressetage`;

        axios.put(
            service, daten
        ).then(res => {
            dispatch(setGlobalSuccess({
                message: "Ihre Anmeldung wurde erfolgreich übermittelt.",
                title: "Vielen Dank!"
            }));
            dispatch(setLoading(false));
            onSuccess && onSuccess(res);
        }).catch((error) => {
            dispatch(setGlobalError({
                title: "Fehler bei der Anmeldung!",
                message: "Bitte kontaktieren Sie uns, damit wir ihre Anfrage bearbeiten und den Fehler beheben können. Telefon: +49 7244-73969-0. E-Mail: kontakt@rbsonline.de"
            }));
            dispatch(setLoading(false));
        });
    }
}
