import { Container,Row,Col } from 'react-bootstrap';
import Image404 from '../../images/404.jpg';

const PageNotFound = () => {

    return (
        <>
      <section className='PageNotFound' style={{backgroundImage: `url(${Image404})`}}>
        <Container>            
            <Row >
                <Col>
                    <h1 className="pb-4 text-white"> Seite wurde nicht gefunden.</h1>
                </Col>
            </Row>
        </Container> 
    </section>   

        </>
    )
}

export default PageNotFound;