import React, { useState, useEffect } from 'react';
import {ReactComponent as ImageFptIcon} from '../../images/fpt-icon.svg';

const ScrollspyNavItemHome = () => {

const [showHomeBtn, setShowHomeBtn] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 1) {
                setShowHomeBtn(true);
            } else {
                setShowHomeBtn(false);
            }
        });
    }, []);

    return (
        <>
            {showHomeBtn && (
                <div className="nav-item custom-icon-fpt-logo"><a href="#Top" className="nav-link"><ImageFptIcon width={30} height={30}/></a></div>
            )}
        </>
    );

};

export default ScrollspyNavItemHome;
