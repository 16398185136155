import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqInahltVortrag"
            header="Inhalt des Vortrags bei der Multi-Pressekonferenz"
            visible={props.visible}
            class='rbs-vortrag-header'
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-4'>
                    <p>Ihr Vortrag sollte technisch orientiert, jedoch nicht nur für absolute Insider verständlich sein.
                        Wählen Sie einen aussage&shy;kräftigen Titel, damit sich auch wirklich alle potenziellen Interessenten ange&shy;spro&shy;chen fühlen.
                        Als Themen eignen sich z. B. Problemlösungen und Anwendungsmöglichkeiten, (wirklich!) neue Produkte, innovative Konzepte,
                        Trends, ...
                    </p>
                    <p> Es ist in aller Regel besser, den Schwerpunkt auf die <strong>Hintergründe der Entwicklung</strong> zu legen, statt Produkte "vorzustellen" und
                        lediglich ihre Funktionen aufzuzählen; einen Überblick über den Stand der Technik und die Trends zu geben (mit Hinweisen auf Ihr
                        eigenes Unternehmen) kommt beispielsweise viel besser an.
                    </p>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <Alert>
                        <strong>Achtung:</strong> Sie sollten keinesfalls über ein Produkt referieren, das die Journalist:innen bereits kennen!
                    </Alert>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;