import React, { useEffect } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadStimmen } from '../../store/actions/fpt';



const Stimmen = () => {

  const dispatch = useDispatch();
  const stimmen = useSelector(state => state.fpt.stimmen);

  useEffect(() => {
    dispatch(loadStimmen(4));
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="text-center mb-5">Stimmen</h1>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col className="mb-5">
          <Carousel slide={false} controls={false} interval={25000} pause={false} variant='dark' className='mb-5'>

            {stimmen && stimmen.map((stimme, index) => {
              return (
                <Carousel.Item key={index}>
                  <div className='StimmenWrapper' dangerouslySetInnerHTML={{__html: stimme.formattedContent}}>
                  </div>
                </Carousel.Item>
              )
            })}

{/* 
            <Carousel.Item>
              <div className='StimmenWrapper'>
                <p className='lead'>
                  <i>"Ich war zum ersten Mal dabei und fand die Veranstaltung sehr gelungen! Die 20-minütigen Vorträge rhythmisieren den Tagesablauf sehr gut, die Pausen sind auch lang genug und die Versorgung mit Essbarem war wirklich prima. Sehr angenehm fand ich, dass man - anders als auf den großen Messen beispielsweise - mit etlichen Leuten auch mehr als einmal sprechen konnte, so baut sich ein schöner Kontakt auf. Auch die Abendveranstaltung hat hierzu beigetragen. Alles in allem eine schöne, runde Veranstaltung! Ich hoffe, nächstes Jahr wieder dabei sein zu können."</i>
                </p>
                <span><strong>Elisabeth Strassert</strong>, Carl Hanser Verlag, Redaktion MONTAGEtechnik</span>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='StimmenWrapper'>
                <p className='lead'>
                  <i>"Die rundherum gelungene Veranstaltung war wieder perfekt organisiert. Ich konnte einiges für den Redaktionsalltag mitnehmen."</i>
                </p>
                <span><strong>Rainer Trummer</strong>, Chefredakteur und Objektleiter AUTOCAD & Inventor Magazin, DIGITAL ENGINEERING MAGAZIN und DIGITAL MANUFACTURING</span>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='StimmenWrapper'>
                <p className='lead'>
                  <i>"Es kam im Verlauf der zwei Tage zu vielen interessanten und hilfreichen Gesprächen, die in unsere weitere Pressearbeit Einzug halten werden. Ein weiterer positiver Effekt war die persönliche Kontaktaufnahme zu dem ein oder anderen Journalisten/In."</i>
                </p>
                <span><strong>Dietmar Baar</strong>, FED e.V., Fachverband für Design, Leiterplatten- und Elektronik&shy;fertigung</span>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='StimmenWrapper'>
                <p className='lead'>
                  <i>"Nach den Presseveröffentlichungen in Folge der Fachpressetage haben wir bereits zahlreiche Kundennachfragen erhalten. Das zeigt, dass bei den Fachpressetagen nicht nur fachkundige Journalisten vor Ort sind, sondern mit ihren Medien auch genau die richtigen Zielgruppen bedienen. Für uns sind die Fachpressetage mittlerweile ein Eckpfeiler in unserem PR-Kalender."</i>
                </p>
                <span><strong>Christoph Holowaty</strong> Wireless Logic mdex GmbH</span>
              </div>
            </Carousel.Item>
            */}

          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default Stimmen;