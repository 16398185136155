import React, { useEffect } from 'react';
import ScrollspyNav from "react-scrollspy-nav";
import { Container, Nav } from 'react-bootstrap';
import ScrollspyNavItemHome from "../layout/ScrollspyNavItemHome";
import SubNavigationTeaserButton from "../layout/SubNavigationTeaserButton";
import Vortrag from "../sections/Vortrag";
import VortragAnmeldung from "../sections/VortragAnmeldung";
import Tisch from "../sections/Tisch";
import TischAnmeldung from "../sections/TischAnmeldung";
import PreiseKonditionen2025 from "../sections/PreiseKonditionen2025";
import RbsPage from '../controls/RbsPage';


const Anmeldung = () => {

  // Das ist ein Hack, um das Captcha-Label zu ändern
  // leider gibt es keine andere Möglichkeit.
  useEffect(() => {
    const captchas = document.getElementsByClassName('modern-react-captcha__inputField');
    if (captchas && captchas[0]) captchas[0].placeholder = 'Zahlen eingeben';
    if (captchas && captchas[1]) captchas[1].placeholder = 'Zahlen eingeben';
  }, []);

  return (
    <RbsPage>

      <nav className="navbar sticky-md-top subnav">
        <Container>
          <ScrollspyNav scrollTargetIds={["Vortrag", "Tisch", "PreiseKonditionen"]} offset={0} activeNavClass="active" scrollDuration="10" headerBackground="true">
            <Nav variant="pills">
              <ScrollspyNavItemHome />
              <Nav.Item>
                <Nav.Link href="#Vortrag">Multi-Pressekonferenz</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Tisch">Pressegespräche</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#PreiseKonditionen">Preise &amp; Konditionen</Nav.Link>
              </Nav.Item>
            </Nav>
          </ScrollspyNav>
        </Container>
        <SubNavigationTeaserButton />
      </nav>

      <section id="Vortrag" className="custom-section-grey scrollIntoViewFix">
        <Vortrag />
        <VortragAnmeldung />
      </section>
      <section id="Tisch">
        <Tisch />
        <TischAnmeldung />
      </section>
      <section id="PreiseKonditionen" className='bg-dark'>
        <PreiseKonditionen2025 />
      </section>

    </RbsPage>
  )

}

export default Anmeldung;
