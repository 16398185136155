import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import ImageFooter1 from '../../images/footer/rbs-logo-white.png';
import ImageFooter2 from '../../images/footer/tipps-logo-white.png';
import ImageFooter3 from '../../images/footer/automationline-logo-white.png';
import ImageFooter4 from '../../images/footer/opportunity-logo.jpg';

function Footer() {
    return (
        <Container className="pt-5">
            <Row className="text-center text-lg-start">
                <Col className="col-12 col-lg-4 rbs-footer-col-about pb-4">
                    <h5 className="pb-2">Über uns</h5>
                    <ul className="list-unstyled">
                        <li>
                            <a href="Kontakt">Kontakt</a> 
                        </li>   
                        <li>
                            <a href="Impressum">Impressum</a> 
                        </li>   
                        <li>
                            <a href="Agb">AGB</a> 
                        </li>   
                        <li>
                            <a href="Datenschutz">Datenschutz</a> 
                        </li>   
                        <li className="pt-3">
                            <a href="https://newsletter.rbsonline.de/"> Newsletter</a> 
                        </li> 
                    </ul>
                </Col>
                <hr className="d-lg-none pb-4"></hr>
                <Col className="col-12 col-lg-8 pb-4">
                    <h5>Weitere Webseiten</h5>
                    <ul className="list-unstyled footer-logo mt-4 clearfix">
                        <li className="pb-3"><a href="http://www.rbsonline.de/" target="_blank" rel="noreferrer"><img src={ImageFooter1} className="rbs-logo" alt="rbs"/></a></li>
                        <li className="pb-3"><a href="http://tipps.rbsonline.de/" target="_blank" rel="noreferrer"><img src={ImageFooter2} className="tipps-logo" alt="tipps"/></a></li>
                        <li className="pb-3"><a href="http://www.automationline.de/" target="_blank" rel="noreferrer"><img src={ImageFooter3} className="automationline-logo" alt="automationline"/></a></li>
                    </ul>
                    <hr className="d-lg-none pb-4"></hr>
                    <h5>Wir unterstützen</h5>
                    <ul className="list-unstyled footer-logo mt-4">
                        <li><a href="https://www.oid.org/" target="_blank" rel="noreferrer"><img src={ImageFooter4} alt="opportunity"/></a></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
  }
  
  export default Footer;