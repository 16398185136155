import React,{ useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadFirmenteilnehmer } from '../../../store/actions/fpt';
import RbsLogoSlider from '../../controls/RbsLogoSlider';
import { shuffle } from '../../../helper/arrayHelper';
import { settings } from '../../../config/settings';

const countCols = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 4
}


const Firmenteilnehmer2023 = () => {

  const sliderRef = useRef();
  const dispatch = useDispatch();
  const firmen = useSelector(state => state.fpt.fpt034.firmen);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(loadFirmenteilnehmer('fpt034'));    
  }, []);

  return (
    <Container>
      <Row>
        <Col className="text-center">
          <h1 className="pb-2 mb-2">Firmenteilnehmer</h1>
          <p className="lead pb-4">Diese Unternehmen waren bei den Fachpressetage 2023 dabei:</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <RbsLogoSlider
            ref={sliderRef}
            countCols={countCols}
            logos={firmen && shuffle(firmen.map(f => ({
              url: f.logoFile && settings.network.logoUrl + f.logoFile,
              description: f.firmenname
            })))}
            speed={3000}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Firmenteilnehmer2023;