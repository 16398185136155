import React from 'react';
import {Tab, Tabs, Container, Carousel, Row, Col, Button} from 'react-bootstrap';
import ImageTeilnahme from '../../images/home/teilnahme.png';
import ImageTeilnahmePlus from '../../images/home/teilnahme-plus.png';
import ImageTeilnahme1 from '../../images/home/teilnahme-01.jpg';
import ImageTeilnahme2 from '../../images/home/teilnahme-02.jpg';
import ImageTeilnahme3 from '../../images/home/teilnahme-03.jpg';

function Teilnahme() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col className='text-center'>
              <h1 className="mb-2 mb-md-4 mb-lg-5">Alle Teilnahme-Möglichkeiten im Vergleich</h1>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col className='g-0'>    
              <div className="TeilnahmeTab">         
              <Tabs defaultActiveKey="networking" justify >
                <Tab eventKey="networking" title="Networking">
                <Container className='pt-5'>
                  <Row>
                    <Col className='col-12 col-md-7 col-lg-6'>
                      <h2 className='mb-3'>Networking-Teilnahme</h2>
                      <p>Wenn Sie mit geringem Aufwand und zu niedrigen Kosten in das "Netzwerk Fachpresse" reinschnuppern möchten, liegen Sie bei dieser Teilnahmeform richtig.</p>
                      <br/>
                      <h4>Vorteile</h4>
                      <ul className='plus'>
                        <li>Geringer Vorbereitungsaufwand</li>
                        <li>Preisgünstig</li>
                        <li>Kontaktpflege, auch wenn Sie gerade keine brandneuen Themen haben</li>
                        <li>Nutzung des Fachpressetage-Portals und der Pressefächer der Redaktionen</li>
                      </ul>
                      <br/>
                      <h4>Nachteile</h4>
                      <ul className='minus'>
                        <li>Keine Möglichkeit zur Präsentation des Unternehmens im Plenum</li>
                        <li>Ihr Unternehmen wird weniger wahrgenommen als bei einem Vortrag oder der Kontakt-Messe</li>
                      </ul>
                    </Col>
                    <Col className='col-12 col-md-5 col-lg-6'>
                    <img src={ImageTeilnahme} className='img-fluid TeilnahmeBild mt-5' alt='Teilnahme' style={{ backgroundImage:`url(${ImageTeilnahme1})` }} />
                    </Col>
                  </Row>
                </Container>                  
                </Tab>
                <Tab eventKey="pressekonferenz" title="Multi-Pressekonferenz">
                <Container className='pt-5'>
                  <Row>
                    <Col className='col-12 col-md-7 col-lg-6'>
                      <h2 className='mb-3'>Multi‑Pressekonferenz</h2>
                      <p>Wenn Sie ein wirklich gutes Thema haben, es öffentlich vorstellen wollen, das Auswahlverfahren (Wahl des Themas durch die eingeladenen Journalisten und Journalistinnen) nicht scheuen und bereit sind, sich sorgfältig auf die Veranstaltung vorzubereiten, ist dies die richtige Teilnahmeform.</p>
                      <br/>
                      <h4>Vorteile</h4>
                      <ul className='plus'>
                        <li>Referent oder Referentin steht 25 Minuten lang voll im Fokus der Fachpresse</li>
                        <li>Redaktionen beschäftigen sich wegen der Wahl schon im Vorfeld mit Firma und Thema</li>
                        <li>Außenwirkung durch Nennung im Programm etwas plakativer als bei Tischmesse</li>
                        <li>Sehr gute Veröffentlichungschancen, wenn zum Vortrag ein passender Pressetext angeboten wird</li>
                      </ul>
                      <br/>
                      <h4>Nachteile</h4>
                      <ul className='minus'>
                        <li>Größerer Vorbereitungsaufwand als bei Tischmesse</li>
                        <li>Gutes Vortragsthema notwendig</li>
                        <li>Risiko, bei der Wahl "durchzufallen"</li>
                      </ul>
                    </Col>
                    <Col className='col-12 col-md-5 col-lg-6'>
                    <img src={ImageTeilnahme} className='img-fluid TeilnahmeBild mt-5' alt='Teilnahme' style={{ backgroundImage:`url(${ImageTeilnahme2})` }} />
                    </Col>
                  </Row>
                </Container>
                </Tab>
                <Tab eventKey="pressetisch" title="Pressegespräche">
                <Container className='pt-5'>
                  <Row>
                    <Col className='col-12 col-md-7 col-lg-6'>
                      <h2 className='mb-3'>Pressegespräch</h2>
                      <p>Wenn Sie individuellen Kontakt zu Journalisten und Journalistinnen suchen, diese gerne einzeln und gezielt informieren möchten, Fachbeiträge, Interviews, Titelbilder, Firmenbesuche und Ähnliches vereinbaren möchten, evtl. nicht das ganz große Thema für einen Vortrag haben oder Ihnen die Vorbereitung eines Fachvortrags zu aufwendig ist, sollten Sie diese Art der Teilnahme wählen.</p>
                      <br/>
                      <h4>Vorteile</h4>
                      <ul className='plus'>
                        <li>Ihr Unternehmen ist durchgehend sichtbar und präsent</li>
                        <li>Journalisten und Journalistinnen einzeln informieren, ohne dass das Plenum mithört</li>
                        <li>Weniger Vorbereitung als bei Vortrag</li>
                        <li>Kein Auswahlverfahren bei der Buchung</li>
                        <li>Sehr gute Veröffentlichungschancen für die mitgebrachten Beiträge</li>
                      </ul>
                      <br/>
                      <h4>Nachteile</h4>
                      <ul className='minus'>
                        <li>Ihr Unternehmen steht weniger im Blickpunkt als eine Referentin oder ein Referent</li>
                      </ul>
                    </Col>
                    <Col className='col-12 col-md-5 col-lg-6'>
                    <img src={ImageTeilnahme} className='img-fluid TeilnahmeBild mt-5' alt='Teilnahme' style={{ backgroundImage:`url(${ImageTeilnahme3})` }} />
                    </Col>
                  </Row>
                </Container>  
                </Tab>
                <Tab eventKey="kombination" title="Kombination / Alternative">
                <Container className='pt-5'>
                  <Row>
                    <Col className='col-12 col-md-7 col-lg-6'>
                      <h2 className='mb-3'>Kombinations-Möglichkeiten</h2>
                      <p>Pressegesprächs-Tisch und Multi-Pressekonferenz lassen sich auch miteinander kombinieren.</p>
                      <br/>
                      <ul className='plus'>
                        <li>Für den Fall, dass Ihr Vortragsthema nicht angenommen wird, können Sie Ihre Anmeldung mit der alternativen Anmeldung eines Messetischs verknüpfen.</li>
                        <li>Sie können einen Pressegesprächs-Tisch auch zusätzlich zum Vortrag buchen.</li>
                      </ul>
                    </Col>
                    <Col className='col-12 col-md-5 col-lg-6'>
                    <Carousel fade controls={false} indicators={false} interval={5000} pause={false} className='mt-5'>
                      <Carousel.Item>
                        <img src={ImageTeilnahmePlus} className='img-fluid TeilnahmeBild' alt='Teilnahme' style={{ backgroundImage:`url(${ImageTeilnahme1})` }} />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={ImageTeilnahmePlus} className='img-fluid TeilnahmeBild' alt='Teilnahme' style={{ backgroundImage:`url(${ImageTeilnahme2})` }} />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={ImageTeilnahmePlus} className='img-fluid TeilnahmeBild' alt='Teilnahme' style={{ backgroundImage:`url(${ImageTeilnahme3})` }} />
                      </Carousel.Item>
                    </Carousel>                    
                    </Col>
                  </Row>
                </Container>  
                </Tab>
              </Tabs></div>   
            </Col>
          </Row>
        </Container>
        <div className='bg-white'>
          <Container>
            <Row className="mb-5">
              <Col className='text-center'>
              <Button href="/Anmeldung#PreiseKonditionen" variant='rbs-button-orange rbs-action-buttons' size='lg'>Preise &amp; Konditionen</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
  
  export default Teilnahme;