import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqWerbegeschenke"
            header="Werbegeschenke"
            visible={props.visible}
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-4'>
                    <p>
                        Werbegeschenke im Rahmen der Fachpressetage sind zwar nicht unbedingt notwendig, können jedoch für einen positiven Eindruck sorgen.
                        Außerdem dienen sie später als Erinnerungshilfe. Ihr Wert sollte jedoch deutlich unter der "Bestechungsgrenze" liegen.
                        Denken Sie dabei auch an die Umwelt!
                    </p>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <p>
                        Weitaus wichtiger als der materielle Wert sind <b>Originalität und Bezug zum Unternehmen oder Vortragsthema.</b> Die Werbegeschenke können
                        beispielsweise mit den Pressemappen an den Sitzplätzen ausgeteilt werden. Vor den Vorträgen besteht außerdem die Möglichkeit, Schreibblöcke
                        und Stifte mit Ihrem Werbeaufdruck auszulegen.
                    </p>
                    <Alert>
                        <b>Hinweis:</b> Legen Sie bitte keine Werbegeschenke in die Presseboxen, da diese sonst schnell übervoll sind!
                    </Alert>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;