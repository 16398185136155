import React from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqRahmenbedingungenVortrag"
            header="Rahmenbedingungen für einen Vortrag bei der Multi-Pressekonferenz"
            visible={props.visible}
            class='rbs-vortrag-header'
        >
            <Row>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <ul className='mb-0'>
                        <li>Für Vortrag samt anschließender Diskussion sind insgesamt <strong>25 Minuten</strong> eingeplant. (Ideale Aufteilung: 20 Minuten Vortrag, 5 Minuten Zeit für Fragen.)</li>
                        <li>Die Vortragssprache ist <strong>Deutsch</strong>. </li>
                        <li>Präsentationsfolien sollten ebenfalls auf Deutsch abgefasst sein.</li>
                        <li>Für die Präsentation verwenden Sie Ihr eigenes Notebook.
                            Testen Sie im Büro Ihr Notebook an einem externen Monitor!</li>
                        <li>Ein Video-Beamer ist im Vortragsraum vorhanden.</li>
                        <li>Empfehlenswert ist es, für den Notfall einen USB-Stick mit allen wichtigen Dateien vorzubereiten,
                            falls Ihr Notebook plötzlich streikt.</li>
                        <li>Bereiten Sie sich auf mögliche Fragen vor! (Wenn Sie eine eventuell stockende Diskussion beleben wollen,
                            können Sie selbst "Notfragen" oder Zusatzinfos vorbereiten.)</li>
                    </ul>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <ul>
                        <li>Der Referent oder die Referentin sollte sich gut mit der technischen Materie auskennen; oft sind das
                            Produktmanager:innen, Entwickler:innen, Geschäftsführer:innen etc. </li>
                        <li>Da niemand vor Krankheit gefeit ist, empfiehlt es sich, eine Vertretung eingeplant zu haben.</li>
                    </ul>
                    <Alert>
                        <b>Wichtig:</b> Die Technik für Ihren Vortrag wird in der vorangehenden Pause vor Ihrem Vortragsblock aufgebaut und das
                        Zusammenspiel Ihres Notebooks mit dem Video-Beamer getestet. Reisen Sie deutlich vor Ihrem Vortrag an, damit Sie auf jeden
                        Fall pünktlich eintreffen. Unser Programm ist so dicht gepackt, dass wir keinen Alternativtermin garantieren können.
                    </Alert>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;