import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './routers/AppRouter';
import { Provider } from "react-redux";
import store from './store/configureStore';
import configWebservice from './config/webservice';

const root = ReactDOM.createRoot(document.getElementById('root'));
configWebservice();

root.render(
  <Provider store={store}>
    <AppRouter />
  </Provider>
);


/* StictMode macht Probleme 
<React.StrictMode>
    <AppRouter />
  </React.StrictMode>

  */