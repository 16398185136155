export const scrollToIdOrDownUp = (hash = undefined) => {
    if (!!hash) {
        const id = hash.replace('#', '');
        console.log("Scroll to " + id);
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }
    else {
        console.log("Scroll downUp ");
        setTimeout(() => {
            scrollToY(1);
            setTimeout(() => scrollToY(0), 100);
        }, 100);
    }
}

const scrollToY = (y) => {
    window.scrollTo(0, y);
} 