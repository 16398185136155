import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ProgrammRaster2023 from './Programmraster2023';


function Programm2023() {

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1 className="text-center pb-5 mb-2">Programm 2023</h1>
          </Col>
        </Row>
       

        <ProgrammRaster2023 />

      </Container>
    </React.Fragment>
  );
}

export default Programm2023;