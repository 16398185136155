import { Alert } from 'react-bootstrap';

function RbsAlert(props) {

  const onAlertClose = () => {
    props.onClose && props.onClose()
  }

  return (
    <Alert
      variant={props.variant === "error" ? "danger FormularAlert shadow" : props.variant === "success" ? "success FormularAlert shadow" : "info FormularAlert shadow"}
      onClose={onAlertClose}
      dismissible
    >
      <Alert.Heading>{props.titel}</Alert.Heading>
      <p>
        {props.error}
      </p>
    </Alert>
  );
}

export default RbsAlert;