import React from "react";
import { Container,Row,Col } from 'react-bootstrap';
import SubNavigation from '../layout/SubNavigation';

const Agb= () => {
  
  return (
    <React.Fragment>
      <nav className="navbar sticky-top subnav">          
        <Container>            
            <SubNavigation defaultActiveKey="Agb" />
        </Container> 
      </nav>
      <section className="custom-section-grey">
        <Container>            
            <Row >
                <Col>
                    <h1 className="pb-4">AGB - Allgemeine Geschäfts­be­dingungen</h1>
                    <h2>Kosten bei Rücktritt</h2>
                    <ul className="content-ul">
                        <li>Vortrag: Bis zur Teilnahmeentscheidung durch die angemeldeten Journalisten ist der Rücktritt kostenlos,
                            danach 25&nbsp;% des Preises. Bei Rücktritt im Zeitraum von 3&nbsp;Monaten vor der Veranstaltung: Gesamtbetrag,
                            sofern der Platz nicht besetzt werden kann, mindestens aber 25&nbsp;% des Preises.
                        </li>
                        <li>Kontaktmesse: bis 3&nbsp;Monate vor der Veranstaltung&nbsp;25 %, danach Gesamtbetrag.</li>
                    </ul>
                    <br/>
                    <h2>
                        Zahlungsbedingungen
                    </h2>
                    <ul className="content-ul">
                        <li>Mit der Teilnahmeentscheidung durch die angemeldeten Journalisten bzw. mit der Anmeldung zur Kontaktmesse wird
                            eine Anzahlung von 25 % des Gesamtbetrags fällig. Alle Rechnungen sind ohne Abzug innerhalb von 10 Tagen nach
                            Rechnungsstellung zahlbar.
                        </li>
                        <li>Alle Preise gelten zuzüglich MwSt.</li>
                    </ul>
                    
                </Col>
            </Row>
        </Container> 
    </section>   
    </React.Fragment>      
  )

}

export default Agb;
