import React from 'react';
import { Accordion, Alert, Button, Col, Row } from 'react-bootstrap';



function FaqItem(props) {

    const scrollToEl = (el) => {
        const element = document.getElementById(props.id);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        //el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        //const y = el.getBoundingClientRect().top - 20;
        //window.scrollTo({ top: y, behavior: 'smooth' });
    }
    
    

    return (
        <React.Fragment>

            {props.visible &&
                <Accordion.Item style={{scrollMarginTop: '100px'}} className={props.class} eventKey={props.id} id={props.id}>
                    <Accordion.Header>{props.header}</Accordion.Header>
                    <Accordion.Body onEntered={(e) => scrollToEl(e)}>
                        {props.children}                       
                    </Accordion.Body>
                </Accordion.Item>
            }

        </React.Fragment>
    );
}

export default FaqItem;