import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'react-bootstrap';
import Navigation from './Navigation';
import '../../styles/App.scss';
import Footer from './Footer';
import { ReactComponent as ImageEnvelope } from '../../images/envelope-fill.svg';
import { ReactComponent as ImageChevronDoubleUp } from '../../images/chevron-double-up.svg';
import { setGlobalError, setGlobalSuccess } from '../../store/actions/global';
import RbsAlert from '../controls/RbsAlert';
import * as ReactGA from "react-ga";
import CookieConsent, { Cookies } from "react-cookie-consent";

const MainLayout = (props) => {

  const dispatch = useDispatch();
  const success = useSelector(state => state.global.success);
  const error = useSelector(state => state.global.error);

  const onSuccessClose = () => {
    dispatch(setGlobalSuccess(undefined))
  }

  const onErrorClose = () => {
    dispatch(setGlobalError(undefined))
  }

  const initGA = (id) => {
    //if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(id);
    //}
  };

  const handleAcceptCookie = () => {
    initGA('G-J5QCQV8TW2');
  }

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };


  return (
    <div className="App">

      {error &&
        <RbsAlert
          variant="error"
          onClose={onErrorClose}
          titel={error.title}
          error={error.message}
        />
      }

      {success &&
        <RbsAlert
          variant="success"
          onClose={onSuccessClose}
          titel={success.title}
          error={success.message}
        />
      }

      <header>
        <Navigation />
      </header>

      <main>
        {props.children}

        {/* 
        <CookieConsent
          overlay
          overlayStyle={{ zIndex: 9999, backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
          style={{ backgroundColor: '#eee', color: '#000' }}
          buttonStyle={{ backgroundColor: '#fc790d', color: '#fff' }}
          onAccept={handleAcceptCookie}
          enableDeclineButton
          onDecline={handleDeclineCookie}
        >
          This website uses cookies.
        </CookieConsent>
        */}
      </main>

      <Button href="https://newsletter.rbsonline.de" variant="rbs-button-orange newsletter-button" target="_blank">Newsletter <ImageEnvelope width={38} height={24} fill='#fff' /></Button>
      <a href="#Top" className="top-link"><ImageChevronDoubleUp width={22} height={22} fill='#fff' /></a>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default MainLayout;
