import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqUebernachten"
            header="Übernachten"
            visible={props.visible}
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <p>
                        Übernachten können Sie direkt am Veranstaltungsort im GenoHotel Karlsruhe. Preise für Übernachtung inkl.
                        Frühstück sowie weitere Informationen entnehmen Sie bitte der Website des
                        Hotels: <a href="https://www.genohotel-karlsruhe.de/de" target="_blank">https://www.genohotel-karlsruhe.de/de </a>
                    </p>
                    <h4>Hier können Sie buchen:</h4>
                    <p>
                        GenoHotel Karlsruhe <br />
                        Fon: <a href='tel:+497219898-0'> +49 721 9898-0 </a><br />
                        Mail: <a href="mailto:hotel@genohotel-karlsruhe.de">hotel@genohotel-karlsruhe.de </a><br />
                    </p>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>

                    <Alert>
                        <strong>Hinweis:</strong> Damit jeder ein Zimmer bekommt, haben wir ein Kontingent reserviert. Bitte geben Sie bei der Buchung die Veranstaltung "Fachpressetage" an.
                    </Alert>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;