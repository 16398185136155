import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqGestaltungPressetext"
            header="Gestaltung Pressetext und Bildmaterial"
            visible={props.visible}
        >
            <Row>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <ul>
                        <li>Text als Fließtext (Auflistungen sollten - wenn überhaupt - nur sparsam verwendet werden)</li>
                        <li>1,5-zeiliger Abstand und Rand für Notizen</li>
                        <li>Möglichst wenig Abkürzungen verwenden, um Missverständnisse zu vermeiden</li>
                        <li>Ein übersichtlicher Ausdruck aller Bilder zum Text samt Bildunterschriften ist für einen schnellen Überblick hilfreich.</li>
                    </ul>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>

                    <Alert>
                        <strong>Tipp</strong>:	Bildmotive mit Praxisbezug sollten Produktfotos vorgezogen werden. Beliebt sind Aufnahmen, bei denen es gelingt,
                        Dynamik oder Aktion einzufangen (z. B. Funkenflug beim Schleifen oder Mensch bei der Arbeit). Falls sinnvoll: Originelle Objekte
                        (mit thematischem Bezug) für Größenvergleich verwenden.
                    </Alert>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;