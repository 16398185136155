import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadFirmenteilnehmer } from '../../../store/actions/fpt';
import { shuffle } from '../../../helper/arrayHelper';
import RbsLogoSlider from '../../controls/RbsLogoSlider';
import { settings } from '../../../config/settings';

const countCols = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 4
}

const Firmenteilnehmer = () => {

  const dispatch = useDispatch();
  const storeFpt = useSelector(state => state.fpt);
  const sliderRef = React.useRef();

  const [selectedFpt, setSelectedFpt] = useState('fpt');

  useEffect(() => {
    selectedFpt && dispatch(loadFirmenteilnehmer(selectedFpt)); // selectedFpt
  }, [selectedFpt]);

  const onTabChange = (key) => {
    setSelectedFpt(key);
  }


  return (
    <React.Fragment>
      <Container>
        <Tab.Container onSelect={onTabChange} activeKey={selectedFpt}>

          <Row className='pt-5 justify-content-center'>
            <Col className='text-end'>
              <h1 className="pt-2 pb-5 mb-2">Firmenteilnehmer</h1>
            </Col>
            <Col>
              <Nav variant="pills" className='d-none d-lg-block RbsNavPills'>
                <Nav.Item>
                  <Nav.Link eventKey="fpt032">2021</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fpt033">2022</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fpt034">2023</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fpt035">2024</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fpt">Alle</Nav.Link>
                </Nav.Item>
              </Nav>
              <Dropdown onSelect={onTabChange} className='d-lg-none RbsDropdown'>
                <Dropdown.Toggle className='btn-rbs-button-orange-inverse'>
                  {settings.fpt[selectedFpt].year ? settings.fpt[selectedFpt].year : 'Alle'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="fpt032">2021</Dropdown.Item>
                  <Dropdown.Item eventKey="fpt033">2022</Dropdown.Item>
                  <Dropdown.Item eventKey="fpt034">2023</Dropdown.Item>
                  <Dropdown.Item eventKey="fpt035">2024</Dropdown.Item>
                  <Dropdown.Item eventKey="fpt">Alle</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row>
            <Col>
              <RbsLogoSlider
                ref={sliderRef}
                countCols={countCols}
                logos={storeFpt && selectedFpt && storeFpt[selectedFpt].firmen &&
                  shuffle(storeFpt[selectedFpt].firmen
                    .filter(f => selectedFpt !== 'fpt' || f.logoFile)
                    .map(f => ({
                      url: f.logoFile && settings.network.logoUrl + f.logoFile,
                      description: f.firmenname
                    }))
                  )
                }
                speed={2000}
                hideDots={selectedFpt === 'fpt'}
              />
            </Col>
          </Row>

        </Tab.Container>
      </Container>
    </React.Fragment>
  );
}

export default Firmenteilnehmer;