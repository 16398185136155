import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { ReactComponent as ImageFlickrLogo } from '../../images/flickr-logo.svg';
import ImageRueckblickPlaceholder from '../../images/rueckblick/fpt-rueckblick-dummy.png';
import { ReactComponent as ImageCaretRight } from '../../images/caret-right-fill.svg';
import { useEffect } from "react";
import { shuffle } from "../../helper/arrayHelper";
import { useDispatch, useSelector } from "react-redux";
import { loadFlickrFptwebsite } from "../../store/actions/global";



function Bilder() {

  const dispatch = useDispatch();
  const images = useSelector(state => state.global.flickrFptwebsite);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    !images && dispatch(loadFlickrFptwebsite());
  }, []);

  return (
    <Container fluid>
      <Row>
        {images && shuffle(images).slice(0, 6).map((item, index) => (
          <Col xs="6" lg="4" className='gx-0' key={index}>
            <img src={ImageRueckblickPlaceholder} alt="Rückblick" className='img-fluid ImageRueckblick' style={{ backgroundImage: `url(${item.image})` }} />
          </Col>
        ))}
        {!images &&
          <>
          <Col xs="6" lg="4" className='gx-0 BilderCol'>
            <img src={ImageRueckblickPlaceholder} alt="Rückblick" className='img-fluid ImageRueckblick' />
            <Spinner animation="border" className="BilderSpinner" />
          </Col>
          <Col xs="6" lg="4" className='gx-0 BilderCol'>
            <img src={ImageRueckblickPlaceholder} alt="Rückblick" className='img-fluid ImageRueckblick' style={{ backgroundColor: `#cdcdcd` }} />
            <Spinner animation="border" className="BilderSpinner" />
          </Col>
          <Col xs="6" lg="4" className='gx-0 BilderCol'>
            <img src={ImageRueckblickPlaceholder} alt="Rückblick" className='img-fluid ImageRueckblick' />
            <Spinner animation="border" className="BilderSpinner" />
          </Col>
          <Col xs="6" lg="4" className='gx-0 BilderCol'>
            <img src={ImageRueckblickPlaceholder} alt="Rückblick" className='img-fluid ImageRueckblick'  style={{ backgroundColor: `#cdcdcd` }} />
            <Spinner animation="border" className="BilderSpinner" />
          </Col>
          <Col xs="6" lg="4" className='gx-0 BilderCol'>
            <img src={ImageRueckblickPlaceholder} alt="Rückblick" className='img-fluid ImageRueckblick' />
            <Spinner animation="border" className="BilderSpinner" />
          </Col>
          <Col xs="6" lg="4" className='gx-0 BilderCol'>
            <img src={ImageRueckblickPlaceholder} alt="Rückblick" className='img-fluid ImageRueckblick'  style={{ backgroundColor: `#cdcdcd` }} />
            <Spinner animation="border" className="BilderSpinner" />
          </Col> 
          </>
        }
      </Row>
      <Button variant='rbs-button-orange rbs-bilder-button' size='lg' href="https://www.flickr.com/photos/140184022@N05/albums" target='_blank'><ImageCaretRight className="ImageCaretRight" width={20} height={20} fill='#fff' /> Mehr Bilder bei flickr <ImageFlickrLogo className='ImageFlickrLogo ms-1' width={20} height={20} fill='#fff' /></Button>
    </Container>
  );
}

export default Bilder;