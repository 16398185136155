import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqSchwerpunktthema"
            header="Schwerpunktthema Pressegespräche"
            visible={props.visible}
            class='rbs-tisch-header'
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-4'>
                    <p>Damit Journalist:innen entscheiden können, ob die Veranstaltung für sie interessant ist, müssen sie vorab die dort
                        präsentierten Themen kennen. Aus diesem Grund sollten Sie ein Schwerpunkt-Thema benennen. Das kann zum
                        Beispiel eine Produktneuheit oder eine zukunftsträchtige Idee sein. Selbstverständlich können Sie mit interessierten
                        Journalist:innen dann auch über andere Themen sprechen und z.B. Titelbilder, Beiträge, Interviews
                        und dergleichen vereinbaren.
                    </p>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>

                    <Alert>
                        Nutzen Sie die Chance und machen Sie <strong>Ihr Thema im Teilnehmer-Portal</strong>&nbsp;
                        sichtbar:<br/><a href="https://portal.fachpresse.live/fpt035/meineThemen" target="_blank" className='btn btn-rbs-button-orange mt-2'>Mein Thema im Portal</a>
                    </Alert>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;