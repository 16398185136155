import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqUploadPortal"
            header="Upload Portal"
            visible={props.visible}
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-3'>
                    <p>
                        Diese Materialien sollten/können Sie ins <a target='_blank' href="https://portal.fachpresse.live">Portal</a> laden: </p>
                    <ul>
                        <li><strong>Digitaler Pressetext </strong>zum Vortrags-Thema bzw. Schwerpunkt-Thema
                            (<strong>vorzugsweise DOCX-Format, keinesfalls als PDF</strong>)</li>
                        <li><strong>Bilder </strong>im JPG-Format (empfehlenswert ist auch ein Bild des Referenten bzw. der Gesprächspartnerin
                            am Presse&shy;gesprächs&shy;tisch
                            für den Wiedererkennungseffekt)</li>
                        <li>Weitere Themen sind möglich, können aber vom Kernthema ablenken und den Ordner unübersichtlich machen.</li>
                    </ul>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <Alert>
                        <strong>Tipp:</strong>	Powerpoint- und PDF-Dateien sind schwer weiterzuverarbeiten und daher höchstens als Hintergrundinformation sinnvoll.
                    </Alert>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;

/*

                        <Button type="submit" className='btn-rbs-button-orange-inverse'>Noch ein Button</Button>

                        */