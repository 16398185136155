import React from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    const linkTo = (id) => {
        props.onLink && props.onLink(id);
    }

    return (
        <FaqItem
            id="FaqInhaltPresemappe"
            header="Inhalt Pressemappe"
            visible={props.visible}
        >

            <Row>
                <Col xs={12} lg={6} className='pe-xl-4'>
                    <p>Sinnvolle Inhalte einer Pressemappe: </p>
                    <ul>
                        <li><strong>Druckbarer Pressetext</strong> zum Vortrag bzw. Schwerpunktthema der Fachpressetage</li>
                        <li><strong>Bildmaterial</strong> zum Pressetext als separate Files; empfehlenswert: Bild des Referenten bzw. Gesprächspartner am Pressegesprächstisch</li>
                        <li>Name, E-Mail und Telefonnummer des <strong>Ansprechpartners</strong> für Redaktionen bei evtl. Rückfragen</li>
                        <li>Handouts mit den Vortragsfolien im "Handzettelmodus"</li>
                        <li>Weitere Pressemeldungen und Fotos können beigelegt werden; zu viele könnten jedoch vom eigentlichen "Kernthema" ablenken.</li>
                    </ul>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>

                    <Alert>
                        <strong>Tipp</strong>:	Überhäufen Sie die Journalisten nicht mit Papier! Dicke Mappen werden häufig vor Ort schon aussortiert (Tackernadeln
                        oder Klarsichthüllen statt Präsentations-Mappen sind dünn und transparent). Auf digitale Speichermedien in der Pressemappe können Sie verzichten,
                        da Sie Ihre Informationen in digitaler Form ins Online-Portal stellen können.
                    </Alert>
                    <p>
                        <h5>Siehe auch:</h5>
                        <Button className='btn-rbs-button-orange-inverse mb-2' size='md' onClick={() => linkTo("FaqGestaltungPressetext")}>Gestaltung Pressetext und Bildmaterial</Button>&nbsp;<br />
                        <Button className='btn-rbs-button-orange-inverse mb-2' size='md' onClick={() => linkTo("FaqAnzahlPressemappen")}>Anzahl Pressemappen</Button>
                    </p>

                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;