import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageFirmenbesuch from '../../images/firmenbesuch.png';
import ImageRbsLogo from '../../images/rbs_logo.gif';

function Firmenbesuch() {

  return (
    <React.Fragment>
    <Container>
      <Row>
        <Col>
          <h2 className="text-center mb-3">Fachpressetage zu Gast bei...</h2>
          <h1 className="text-center pb-5 mb-2">...Ihnen?</h1>
        </Col>
      </Row>
      <Row className="gx-lg-4 gx-xl-5 pb-4">
        <Col className="col-12 col-lg-6">
          <p>2023 haben wir beschlossen, kein Unternehmen der Region Karlsruhe zu besuchen. Auf vielfachen Wunsch werden wir die Zeit für Networking bei unserem beliebten Meet-the-Press-Abend nutzen. Wenn Sie interessiert sind, dass wir 2024 zu Ihnen kommen und Sie ein spannendes Thema haben, können Sie uns gerne ansprechen.</p>
        </Col>
        <Col className="col-12 col-lg-6 FirmenbesuchBubble text-center p-5">
          <p>
            Hier könnte Ihr Logo stehen
          </p>        
          <img src={ImageRbsLogo} className='img-fluid' alt='Rbs' /> 
        </Col>
      </Row>
    </Container>
    <img src={ImageFirmenbesuch} className='img-fluid' alt='Firmenbesuch' />
    </React.Fragment>
  );
}

export default Firmenbesuch;