import React from 'react';
import FaqItem from '../../controls/FaqItem';
import { Col, Row } from 'react-bootstrap';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqZimmerBuchen"
            header="Zimmer buchen"
            visible={props.visible}
        >
            <Row>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <p>Falls Sie ein Zimmer benötigen, buchen Sie es bitte selbst direkt im GenoHotel. Sie wissen selbst am besten, wann Sie an- und abreisen.
                        Die Kosten für die anwesenden Journalist:innen übernimmt das RBS. Nennen Sie dazu bei der Buchung
                        das <strong>Stichwort "Fachpressetage Journalist"</strong>.
                    </p>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>

                    <p>
                        GenoHotel Karlsruhe <br />
                        Fon: <a href="tel:+49 721 9898-0">+49 721 9898-0</a> <br />
                        Mail: <a href="mailt:hotel@genohotel-karlsruhe.de">hotel@genohotel-karlsruhe.de </a><br />
                    </p>
                </Col>
            </Row>
        </FaqItem>
    );
}

export default FaqVortrag;