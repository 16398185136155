import React from 'react';
import { useDispatch } from 'react-redux';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { anmeldenFachpressetage } from '../../store/actions/fpt';
import RbsCaptcha from '../controls/RbsCaptcha';


function TischAnmeldung() {

  const dispatch = useDispatch();
  const defaultFormData = {
    teilnahme: 'Pressegesprächs-Tisch',
    firma: '',
    name: '',
    email: '',
    thema: '',
    bemerkung: '',
    datenschutz: false
  };

  const [userInputCaptcha, setUserInputCaptcha] = React.useState(undefined);
  const [captchaOk, setCaptchaOk] = React.useState(false);
  const [formData, setFormData] = React.useState(defaultFormData);

  const onAnmeldungSuccess = () => {
    setFormData(defaultFormData);
  }

  const onFormSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity()) {
      //console.log("data", formData);
      dispatch(anmeldenFachpressetage(formData.email, formData, onAnmeldungSuccess));
    }
  }

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  return (
    <React.Fragment>
      <Container>
        <Row className='mt-4 mt-lg-2 mt-xl-2'>
          <Col>
            <h2 className='pb-4'>Anmeldung Pressegesprächs-Tisch</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 4, order: 'last' }} xl={5}>
            <h4 className='mb-1'>Hinweis</h4>
            <p>
              Die Teilnahme beinhaltet automatisch das Grundpaket Networking-Teilnahme. Wenn Sie einen
              Pressegesprächs-Tisch zusätzlich zum Fachvortrag buchen, fallen die Kosten für die
              Networking-Teilnahme nur einmalig an.
            </p>
          </Col>
          <Col xs={12} lg={8} xl={7}>

            <Form onSubmit={onFormSubmit}>

              <Form.Group as={Row} className="mb-3" controlId="FormAnmeldungTischFirma">
                <Form.Label column="sm" sm={4}>
                  Firma *
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    required
                    size="sm"
                    type="text"
                    placeholder="Firma"
                    name="firma"
                    value={formData.firma}
                    onChange={onInputChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="FormAnmeldungTischName">
                <Form.Label column="sm" sm={4}>
                  Ansprechpartner:in *
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    required
                    size="sm"
                    type="text"
                    name='name'
                    placeholder="Vorname und Nachname"
                    value={formData.name}
                    onChange={onInputChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="FormAnmeldungTischEmail">
                <Form.Label column="sm" sm={4}>
                  E-Mail Ansprechpartner:in  *
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    required
                    size="sm"
                    type="email"
                    placeholder="Mailadresse"
                    name="email"
                    value={formData.email}
                    onChange={onInputChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="FormAnmeldungTischThema">
                <Form.Label column="sm" sm={4}>
                  Thema
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Thema (falls bekannt)"
                    name='thema'
                    value={formData.thema}
                    onChange={onInputChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="FormAnmeldungTischBemerkung">
                <Form.Label column="sm" sm={4}>
                  Bemerkung
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    placeholder="Ihre Nachricht an uns"
                    name='bemerkung'
                    value={formData.bemerkung}
                    onChange={onInputChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="FormAnmeldungTischDatenschutz">
                <Form.Label column="sm" sm={4}>
                  Datenschutz *
                </Form.Label>
                <Col sm={8}>
                  <Form.Check
                    required
                    className='FormTCheckLabelSmall'
                    name='datenschutz'
                    checked={formData.datenschutz}
                    onChange={onCheckboxChange}
                    label={
                      <span>
                        Ich habe die <a href="/AGB" target="_blank" rel="noopener noreferrer">AGB</a> und <a href="/Datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a> gelesen und stimme zu.
                      </span>}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="FormAnmeldungTischCaptcha">
                <Form.Label column="sm" sm={4}>
                </Form.Label>
                <Col sm={5} xs={5} style={{ marginLeft: '10px', background: captchaOk ? '#198754' : '#d32f2f', padding: '15px', borderRadius: '5px' }}>
                  <RbsCaptcha
                    id="captchaTischAnmeldung"
                    length={4}
                    userInput={userInputCaptcha}
                    onCaptchaCheck={(res) => setCaptchaOk(res)}
                  />
                  <Form.Control
                    style={{ marginTop: '8px' }}
                    size="sm"
                    type="text"
                    placeholder="Bitte Captcha eingeben"
                    name='txtCaptch'
                    value={userInputCaptcha}
                    onChange={(event) => setUserInputCaptcha(event.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Col sm={{ span: 8, offset: 4 }}>
                  <Button type="submit" disabled={!captchaOk} className='btn-rbs-button-orange'>Pressegesprächs-Tisch buchen</Button>
                </Col>
              </Form.Group>

            </Form>
          </Col>
        </Row>
      </Container>

    </React.Fragment>
  );
}

export default TischAnmeldung;