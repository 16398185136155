import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, Button, ButtonGroup, Col, Container, Form, Row } from 'react-bootstrap';

import FaqAnreise from './faq/FaqAnreise';
import FaqZimmerBuchen from './faq/FaqZimmerBuchen';
import FaqAnwesenheitPortal from './faq/FaqAnwesenheitPortal';
import FaqZeitschriftenAuslegen from './faq/FaqZeitschriftenAuslagen';
import FaqZielFPT from './faq/FaqZiel';
import FaqUebernachten from './faq/FaqUbernachten';
import FaqRahmenbedingungenVortrag from './faq/FaqRahmenbedingungenVortrag';
import FaqInhaltVortrag from './faq/FaqInhaltVortrag';
import FaqMaterialVortrag from './faq/FaqMaterialVortrag';
import FaqRahmenbedingungenTisch from './faq/FaqRahmenbedingungenTisch';
import FaqSchwerupnktthema from './faq/FaqSchwerpunktthema';
import FaqMaterialTisch from './faq/FaqMaterialTisch';
import FaqInhaltPressemappe from './faq/FaqInhaltPressemappe';
import FaqGestaltungPressetext from './faq/FaqGestaltungPressetext';
import FaqAnzahlPressemappen from './faq/FaqAnzahlPressemappen';
import FaqUploadPortal from './faq/FaqUploadPortal';
import FaqKontaktpflege from './faq/FaqKontaktpflege';
import FaqWerbegeschenke from './faq/FaqWerbegeschenke';
import FaqPortalZugangsdaten from './faq/FaqPortalZugangsdaten';
import FaqTextVomRBS from './faq/FaqTextVomRBS';
import FaqVorabend from './faq/FaqVorabend';


const Faq_Section = (props) => {

  let { selectedId } = useParams();

  const [selectedFaq, setSelectedFaq] = React.useState(undefined);
  const [selection, setSelection] = React.useState({
    isFirma: true,
    tisch: true,
    vortrag: true,
  })


  useEffect(() => {
    if (!props.teilnehmerTyp) return;
    if (props.teilnehmerTyp.length === 0) return;

    setSelection({
      isFirma: props.teilnehmerTyp?.includes('firma'),
      tisch: props.teilnehmerTyp?.includes('tisch'),
      vortrag: props.teilnehmerTyp?.includes('vortrag'),
    });
  }, [props.teilnehmerTyp]);


  useEffect(() => {
    setSelectedFaq(selectedId);
    //scrollToId(selectedId);
  }, [selectedId]);

  useEffect(() => {
    //selectedFaq && scrollToId(selectedFaq);
  }, [selectedFaq]);


  const scrollToId = (id) => {
    // Scroll to id
    const element = document.getElementById(selectedFaq);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" });
    }
  }

  const setFirmaRedaktion = (isFirma) => {
    if (isFirma) {
      setSelection({
        isFirma: true,
        tisch: true,
        vortrag: true,
      })
    } else {
      setSelection({
        isFirma: false,
        tisch: false,
        vortrag: false,
      })
    }
  }


  const getSelectionArray = () => {
    if (!selection.isFirma) {
      return ['redakteur']
    } else {
      const arr = []
      arr.push('networking');
      if (selection.tisch) arr.push('tisch');
      if (selection.vortrag) arr.push('vortrag');
      return arr;
    }
  }


  const visibleFor = (visibleArray) => {
    return getSelectionArray().some((item) => visibleArray.includes(item));
  }

  const onAccordionSelect = (key) => {
    setSelectedFaq(key);
  }

  const selectId = (id) => {
    setSelectedFaq(id);
  }

  return (
    <Container className='mb-5'>
      {selectedId}
      <Row>
        <Col>
          <h1 className="mb-5 ext-center">Wichtige Informationen für Teilnehmer:innen</h1>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col lg='auto'>
          Ich vertrete eine
          <ButtonGroup size="md" className="mb-2 ms-2">
            <Button
              onClick={() => setFirmaRedaktion(true)}
              variant={selection.isFirma ? "primary" : "secondary"} className='rbs-button-group'
            >
              Firma
            </Button>
            <Button
              onClick={() => setFirmaRedaktion(false)}
              variant={!selection.isFirma ? "primary" : "secondary"} className='rbs-button-group'
            >
              Redaktion
            </Button>
          </ButtonGroup>
        </Col>
        {selection.isFirma &&
          <Col lg='6'>

            <Form style={{ background: 'none', border: 'none' }} className='p-0 py-2 m-0'>
              mit&nbsp;&nbsp;
              <Form.Check inline
                type='checkbox'
                id="vortrag"
                label="Vortrag"
                checked={selection.vortrag}
                onChange={() => setSelection({ ...selection, vortrag: !selection.vortrag })}
              />
              <Form.Check inline
                type='checkbox'
                id="tisch"
                label="Pressegesprächs-Tisch"
                checked={selection.tisch}
                onChange={() => setSelection({ ...selection, tisch: !selection.tisch })}
              />
            </Form>

          </Col>
        }
      </Row>


      <Row className="gx-lg-4 gx-xl-5">
        <Col className="col-12 mb-4 mb-lg-0">
          <Accordion className='rbs-accordion' onSelect={onAccordionSelect} activeKey={selectedFaq}>

            <FaqZielFPT visible={visibleFor(['tisch', 'vortrag', 'networking'])} />
            <FaqKontaktpflege onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'networking'])} />
            <FaqAnreise onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'redakteur', 'networking'])} />
            <FaqZimmerBuchen onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['redakteur'])} />
            <FaqVorabend onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['redakteur'])} />
            <FaqAnwesenheitPortal onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['redakteur'])} />
            <FaqUebernachten onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'networking'])} />
            <FaqRahmenbedingungenVortrag onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['vortrag'])} />
            <FaqInhaltVortrag onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['vortrag'])} />
            <FaqMaterialVortrag onLink={(id) => selectId(id)} visible={visibleFor(['vortrag'])} />
            <FaqRahmenbedingungenTisch onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch'])} />
            <FaqSchwerupnktthema onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch'])} />
            <FaqMaterialTisch onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch'])} />
            <FaqInhaltPressemappe onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'networking'])} />
            <FaqGestaltungPressetext onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'networking'])} />
            <FaqAnzahlPressemappen onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'networking'])} />
            <FaqUploadPortal onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'networking'])} />
            <FaqWerbegeschenke onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'networking'])} />
            {/*<FaqPortalZugangsdaten onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'redakteur', 'networking'])} />*/}
            <FaqTextVomRBS onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['tisch', 'vortrag', 'networking'])} />
            <FaqZeitschriftenAuslegen onLink={(id) => setSelectedFaq(id)} visible={visibleFor(['redakteur'])} />

          </Accordion>
        </Col>
      </Row>

    </Container>
  )

}

export default Faq_Section;
