import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, Card } from 'react-bootstrap';
import ImageThemen from '../../../images/home/1920x466.png';
import { useDispatch, useSelector } from 'react-redux';
import { loadRedaktionen } from '../../../store/actions/fpt';
import { settings } from '../../../config/settings';


const dummyImage = require('../../../images/redaktion-dummy.png');

function Redaktionen() {

  const dispatch = useDispatch();
  const state = useSelector(state => state.fpt);

  const [show, setShow] = useState(undefined);

  useEffect(() => {
    show && dispatch(loadRedaktionen(show));
  }, [show]);

  const handleClose = () => setShow(undefined);

  return (
    <React.Fragment>
      <img src={ImageThemen} className='img-fluid rbs-redaktionen-bild' alt='Redaktionen'></img>
      <Container>
        <Row>
          <Col className='text-center'>
            <h1 className="pb-4">Redaktionen</h1>
          </Col>
        </Row>
        <Row className="gx-lg-4 gx-xl-5 pb-4">
          <Col className="col-12 col-lg-6">
            <p>An den bisher 34 Fachpressetagen waren <strong>über 270 Redaktionen</strong> vertreten. Diese beschäftigen sich mit verschiedenen Teildisziplinen der Automatisierungstechnik.</p>
          </Col>
          <Col className="col-12 col-lg-6">
            <p>Da die Themen jährlich etwas variieren, laden wir immer passend zum Programm ein. In den Listen sehen Sie, ob die wichtigen Fachzeitschriften aus Ihrem Umfeld vertreten waren</p>
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <h2 className="pb-4">Teilnehmerlisten:</h2>
            <Button variant='rbs-button-orange mb-lg-0 mx-2 px-4' size='lg' onClick={() => setShow('fpt032')}>2021 (Digital)</Button>
            <Button variant='rbs-button-orange mb-lg-0 mx-2 px-4' size='lg' onClick={() => setShow('fpt033')}>2022</Button>
            <Button variant='rbs-button-orange mb-lg-0 mx-2 px-4' size='lg' onClick={() => setShow('fpt034')}>2023</Button>
            <Button variant='rbs-button-orange mb-lg-0 mx-2 px-4' size='lg' onClick={() => setShow('fpt035')}>2024</Button>
            <Button variant='rbs-button-orange mb-lg-0 mx-2 px-4' size='lg' onClick={() => setShow('fpt')}>Alle</Button>
          </Col>
        </Row>
      </Container>

      <Modal
        show={!!show}
        onHide={handleClose}
        animation={false}
        dialogClassName="modal-dialog modal-xl RbsModal RbsRedaktionenModal"
      >
        <Modal.Header closeButton className='modal-header--sticky'>
          <Modal.Title><strong>Redaktionen</strong> {show && settings.fpt[show] && settings.fpt[show].year}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row xs={1} lg={2} xl={3} className="g-4">
            {state && show && state[show].redaktionen && state[show].redaktionen.map((item, index) => (
              <Col key={index}>
                <a href={item.www} target="_blank" rel="noreferrer" className='RbsRedaktionenLink'>
                  <Card className='RedaktionCard text-center'>
                    <Card.Body>
                      <div className='RedaktionImage d-flex align-items-end justify-content-center'>
                        <div>
                          <img src={item.logoFile ? settings.network.logoUrl + item.logoFile : dummyImage} className='ImageRedaktionLogo' alt={item.firmenname} />
                        </div>
                      </div>
                      <Card.Title>{item.firmenname}</Card.Title>
                      <Card.Text>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>

    </React.Fragment>

  );
}

export default Redaktionen;