const globalReducerDefaultState = {
    error: undefined, // { title: 'Fehler', message: 'Es ist ein Fehler aufgetreten.' },
    success: undefined, // { title: 'Erfolg', message: 'Die Aktion war erfolgreich.' },
    warning: undefined,
    loading: false,
    flickrFptwebsite: undefined,
};


const globalReducer = (state = globalReducerDefaultState, action) => {

    switch (action.type) {
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.value
            }
        case 'SET_GLOBAL_WARNING':
            return {
                ...state,
                warning: action.warning,
            }
        case 'SET_GLOBAL_ERROR':
            return {
                ...state,
                error: action.error
            }
        case 'SET_GLOBAL_SUCCESS':
            return {
                ...state,
                success: action.success,
            }
        case 'SET_FLICKR_FPTWEBSITE':
            return {
                ...state,
                flickrFptwebsite: action.images
            }
        default:
            return state;
    }
}

export default globalReducer;