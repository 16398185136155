import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollToIdOrDownUp } from "../../helper/pageHelper";

const RbsPage = (props) => {

    // force scroll to Segment in hash on page load or DownUp to fix ScrollSpy issue
    const { hash } = useLocation();
    
    useEffect(() => {
        scrollToIdOrDownUp(hash);
    }, [hash]);

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
};

export default RbsPage;