import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (
        <FaqItem
            id="FaqAnzahlPressemappen"
            header="Anzahl Pressemappen"
            visible={props.visible}
        >
            <Row>
                <Col xs={12} lg={6} className='pe-xl-4'>
                    <p>
                        Wir informieren Sie kurz vor der Veranstaltung, wie viele Presse&shy;mappen benötigt werden.
                        Alternativ hilft ihnen ein Blick ins <a target='_blank' href="https://portal.fachpresse.live">Portal</a>. Im Bereich
                        "Wer kommt wann" können Sie sehen, wie viele Redak&shy;tionen für die Veranstaltung angemeldet sind. Das kann sich aller&shy;dings
                        relativ kurzfristig noch ändern.
                    </p>
                </Col>
            </Row>

        </FaqItem>
    );
}

export default FaqVortrag;