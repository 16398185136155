import React, { Fragment, useState } from "react"
import { Col, Row } from "react-bootstrap";
import { ReactComponent as ImageCaretRight } from '../../../images/caret-right-fill.svg';
import FptProgrammModal from "../../controls/FptProgrammModal";

const ProgrammRaster2022 = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [programmData, setProgrammData] = useState({
        tag: undefined,
        datum: undefined,
        wochentag: undefined,
        xmlFile: undefined,
        idFpt: undefined
    });

    //#region FormEvents

    const bttnDetailTag1Click = () => {
        setProgrammData({
            tag: 'tag1',
            datum: '23.03.2022',
            wochentag: 'Mittwoch',
            xmlFile: 'fpt033_tag1.xml',
            idFpt: 'fpt033'
        });
        setShowModal(true);
    }

    const bttnDetailTag2Click = () => {
        setProgrammData({
            tag: 'tag2',
            datum: '24.03.2022',
            wochentag: 'Donnerstag',
            xmlFile: 'fpt033_tag2.xml',
            idFpt: 'fpt033'
        });
        setShowModal(true);
    }


    return (
        <Fragment>
            <Row className='pb-4 pb-lg-0'>
                <Col xs={12} md={6} className='pb-4'>
                    <div className="d-flex flex-column programm-wrapper" onClick={bttnDetailTag1Click}>
                        <div className="programm-white shadow-sm">
                            <strong>Mittwoch</strong> 23.03.2022
                        </div>
                        <div className="programm-orange shadow-sm">
                            Antreiben und<br />
                            Bewegen<br /><br />
                            Sensorik<br />
                            <br />
                        </div>
                        <div className="programm-black shadow-sm">
                            Meet-the-PressAbend
                        </div>
                        <div className="programm-link">
                            <ImageCaretRight className="ProgrammDetailsButtonCaret" /> Details anzeigen
                        </div>
                    </div>-
                </Col>
                <Col xs={12} md={6}>
                    <div className="d-flex flex-column programm-wrapper" onClick={bttnDetailTag2Click}>
                        <div className="programm-white shadow-sm">
                            <strong>Donnerstag</strong> 24.03.2022
                        </div>
                        <div className="programm-orange shadow-sm">
                            Prozesstechnik<br /><br />
                            Digitalisierung, Steuerung, IoT, Netze
                            <br />
                            <br />
                        </div>
                        <div className="programm-link">
                            <ImageCaretRight className="ProgrammDetailsButtonCaret" /> Details anzeigen
                        </div>
                    </div>
                </Col>
            </Row>


            {showModal &&
                <FptProgrammModal onClose={() => setShowModal(false)} data={programmData} />
            }
        </Fragment>

    )
}
export default ProgrammRaster2022;