import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import {ReactComponent as ImagePlayButton} from '../../images/play-btn-fill.svg';
import ImageVideoPlaceholder from '../../images/rueckblick/fpt-video-dummy.png';

const FptVideo = [
  {
    link: 'https://youtu.be/PFkX0BlfiFw',
    image: require('../../images/rueckblick/video-2021-vega.jpg'),
    company: 'Vega',
    text: 'Autarke Füllstandmessung erobert neue Anwendungen',
  },
  {
    link: 'https://youtu.be/-exmgaCZyl4',
    image: require('../../images/rueckblick/video-2019-pepperl-fuchs.jpg'),
    company: 'Pepperl+Fuchs',
    text: 'Die Zeit ist reif für Ethernet-APL',
  },
  {
    link: 'https://youtu.be/ze1sXSfr7y0',
    image: require('../../images/rueckblick/video-2018-balluff.jpg'),
    company: 'Balluff',
    text: 'Sensorik der vollen Transparenz',
  },
  {
    link: 'https://youtu.be/F_pPVqS4qpU',
    image: require('../../images/rueckblick/video-2018-igus.jpg'),
    company: 'Igus',
    text: 'Schlanke Robotik für einfache Aufgaben',
  },
  {
    link: 'https://youtu.be/pFOW0_r8sYg',
    image: require('../../images/rueckblick/video-2018-omron.jpg'),
    company: 'Omron',
    text: 'Weniger Daten im richtigen Kontext sind mehr',
  },
  {
    link: 'https://youtu.be/N2KfssLrpXg',
    image: require('../../images/rueckblick/video-2022-leuze.jpg'),
    company: 'Leuze electronic',
    text: 'Sichere Positionserfassung',
  },
  {
    link: 'https://youtu.be/HvDAY-35r80',
    image: require('../../images/rueckblick/video-2019-koco.jpg'),
    company: 'Koco Motion',
    text: 'Antriebs-Systemanbieter – Tendenz steigend',
  },
  {
    link: 'https://www.youtube.com/watch?v=Vv4jb98e5Fg',
    image: require('../../images/rueckblick/video-2022-logiccloud.jpg'),
    company: 'Logiccloud',
    text: 'Die SPS-Steuerung direkt aus der Cloud.',
  },
]

function Videos() {

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="text-center pb-5 mb-2">Videos</h1>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={3} xl={4} className="g-4">
      {FptVideo.map((item, index) => (
        <Col key={index}>
          <Card className='text-bg-dark VideoCard mx-auto'>
            <Card.Body className='p-1'>
              <Card.Text>
                <a href={item.link} target="_blank" rel="noreferrer" className='VideoImageButton'><img src={ImageVideoPlaceholder} className='img-fluid ImagePlaceholder' alt='Themen' style={{backgroundImage: `url(${item.image})`}} /><ImagePlayButton className="ImagePlayButton" width={80} height={80} fill='#fff' /></a>  
              </Card.Text>
            </Card.Body>
            <Card.Footer>   
              <h4 className='pb-0 mb-1'>{item.company}</h4>
              <p className='mb-1'>{item.text}</p>
            </Card.Footer>
          </Card>  
        </Col>
      ))}        
      </Row>
    </Container>
  );
}

export default Videos;