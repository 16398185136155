import React from "react";
import { Container,Row,Col } from 'react-bootstrap';
import SubNavigation from '../layout/SubNavigation';

const Datenschutz= () => {
  
  return (
    <React.Fragment>
      <nav className="navbar sticky-top subnav">          
        <Container>            
            <SubNavigation defaultActiveKey="Datenschutz" />
        </Container> 
      </nav>
      <section className="custom-section-grey">
        <Container>            
            <Row >
                <Col>
                    <h1 className="pb-4">Datenschutzerklärung</h1>

<h2 className="pt-4">§ 1 Allgemeines</h2>

<p className="Paragraph"><span lang="DE">Ihre personenbezogenen Daten (z.&nbsp;B.
Anrede, Name, Anschrift, E-Mail-Adresse, Telefonnummer) werden von uns nur
gemäß den Bestimmungen des deutschen Datenschutzrechts und des
Datenschutzrechts der Europäischen Union (EU) verarbeitet. Die nachfolgenden
Vorschriften informieren Sie neben den Verarbeitungszwecken, Rechtsgrundlagen,
Empfängern, Speicherfristen auch über Ihre Rechte und den Verantwortlichen für
Ihre Datenverarbeitung. Diese Datenschutzerklärung bezieht sich nur auf unsere
Websites. Falls Sie über Links auf unseren Seiten auf andere Seiten
weitergeleitet werden, informieren Sie sich bitte dort über den jeweiligen
Umgang mit Ihren Daten.</span></p>

 

 

<h2 className="pt-5">§ 2 Kontaktaufnahme</h2>

 

<p className="Paragraph"><b><span lang="DE">(1) Verarbeitungszweck</span></b></p>

<p className="Paragraph"><span lang="DE">Ihre personenbezogenen Daten, die Sie uns per
E-Mail, Kontaktformular etc. zur Verfügung stellen, verarbeiten wir zur
Beantwortung und Erledigung Ihrer Anfragen. Sie sind nicht verpflichtet, uns
Ihre personenbezogenen Daten bereitzustellen. Aber ohne Mitteilung Ihrer
E-Mail-Adresse können wir Ihnen auch nicht per E-Mail antworten.</span></p>

<p className="Paragraph"><b><span lang="DE">(2) Rechtsgrundlagen</span></b></p>

<p className="Paragraph"><span lang="DE">a) Sollten Sie uns eine ausdrückliche
Einwilligung zur Verarbeitung Ihrer Daten gegeben haben, ist Art.&nbsp;6
Abs.&nbsp;1a) DSGVO die Rechtsgrundlage für diese Verarbeitung.</span></p>

<p className="Paragraph"><span lang="DE">b) Sollten wir Ihre Daten zur Durchführung
vorvertraglicher Maßnahmen verarbeiten, ist Art.&nbsp;6 Abs.&nbsp;1b) DSGVO die
Rechtsgrundlage.</span></p>

<p className="Paragraph"><span lang="DE">c) In allen anderen Fällen (insbesondere bei
Nutzung eines Kontaktformulars) ist Art.&nbsp;6 Abs.&nbsp;1f) DSGVO die
Rechtsgrundlage.</span></p>

<p className="Paragraph"><b><span lang="DE">WIDERSPRUCHSRECHT: Sie haben das Recht, der
Datenverarbeitung, die auf der Grundlage des Art.&nbsp;6 Abs.&nbsp;1f) DSGVO
erfolgt und nicht der Direktwerbung dient, aus Gründen, die sich aus Ihrer
besonderen Situation ergeben, jederzeit zu widersprechen.</span></b></p>

<p className="Paragraph"><b><span lang="DE">Im Falle der Direktwerbung können Sie der
Verarbeitung hingegen ohne Angabe von Gründen jederzeit widersprechen. </span></b></p>

<p className="Paragraph"><b><span lang="DE">(3) Berechtigtes Interesse</span></b></p>

<p className="Paragraph"><span lang="DE">Unser berechtigtes Interesse an der
Verarbeitung besteht darin, mit Ihnen auf schnellem Wege zu kommunizieren und Ihre
Anfragen kostengünstig zu beantworten. Wenn Sie uns Ihre Anschrift mitteilen,
behalten wir uns vor, diese für postalische Direktwerbung zu verwenden. Ihr
Interesse am Datenschutz können Sie durch eine sparsame Datenweitergabe
(z.&nbsp;B. Verwendung eines Pseudonyms) wahren.</span></p>

<p className="Paragraph"><b><span lang="DE">(4) Empfängerkategorien</span></b></p>

<p className="Paragraph"><span lang="DE">Hostinganbieter, Versanddienstleister bei
Direktwerbung</span></p>

<p className="Paragraph"><b><span lang="DE">(5) Speicherdauer</span></b></p>

<p className="Paragraph"><span lang="DE">Ihre Daten werden gelöscht, wenn sich aus den
Umständen entnehmen lässt, dass Ihre Anfrage bzw. der betroffene Sachverhalt
abschließend geklärt ist.</span></p>

<p className="Paragraph"><span lang="DE">Falls es jedoch zu einem Vertragsschluss
kommt, werden die nach Handels- und Steuerrecht erforderlichen Daten von uns
für die gesetzlich bestimmten Zeiträume aufbewahrt, also regelmäßig zehn Jahre
(vgl. §&nbsp;257 HGB, §&nbsp;147 AO).</span></p>

<p className="Paragraph"><b><span lang="DE">(6) Widerrufsrecht</span></b></p>

<p className="Paragraph"><span lang="DE">Sie haben im Fall der Verarbeitung aufgrund
Ihrer Einwilligung das Recht, Ihre Einwilligung jederzeit zu widerrufen.</span></p>

 

 

<h2 className="pt-5">§ 3 Kommentare</h2>

 

<p className="Paragraph"><b><span lang="DE">(1) Verarbeitungszweck</span></b></p>

<p className="Paragraph"><span lang="DE">Es besteht die Möglichkeit einen Kommentar zu
verfassen. Ihre Daten (z.&nbsp;B. Name/Pseudonym, E-Mail-Adresse, Website)
werden dann nur für den Zweck der Veröffentlichung Ihres Kommentars
verarbeitet.</span></p>

<p className="Paragraph"><b><span lang="DE">(2) Rechtsgrundlage</span></b></p>

<p className="Paragraph"><span lang="DE">Rechtsgrundlage für diese Verarbeitung ist
Art.&nbsp;6 Abs.&nbsp;1f) DSGVO.</span></p>

<p className="Paragraph"><b><span lang="DE">(3) Berechtigtes Interesse</span></b></p>

<p className="Paragraph"><span lang="DE">Unser berechtigtes Interesse ist der öffentliche
Austausch von Nutzermeinungen zu bestimmten Themen und Produkten. Die
Veröffentlichung dient u.a. der Transparenz und Meinungsbildung. Ihr Interesse
am Datenschutz bleibt gewahrt, da Sie Ihren Kommentar unter einem Pseudonym
veröffentlichen können.</span></p>

<p className="Paragraph"><b><span lang="DE">(4) Speicherdauer</span></b></p>

<p className="Paragraph"><span lang="DE">Eine bestimmte Speicherdauer ist nicht
vorgesehen. Sie können jederzeit die Löschung Ihres Kommentars verlangen.</span></p>

<p className="Paragraph"><b><span lang="DE">(5)&nbsp; WIDERSPRUCHSRECHT</span></b></p>

<p className="Paragraph"><b><span lang="DE">Sie haben das Recht, der Datenverarbeitung
die auf der Grundlage des Art.&nbsp;6 Abs.&nbsp;1f) DSGVO erfolgt und nicht der
Direktwerbung dient aus Gründen, die sich aus Ihrer besonderen Situation
ergeben, jederzeit zu widersprechen.</span></b></p>

<p className="Paragraph"><b><span lang="DE">Im Falle der Direktwerbung können Sie der
Verarbeitung hingegen ohne Angabe von Gründen jederzeit widersprechen.</span></b></p>

 

 

<h2 className="pt-5">§ 4 Informationen über Cookies</h2>

 

<p className="Paragraph"><b><span lang="DE">(1) Verarbeitungszweck</span></b></p>

<p className="Paragraph"><span lang="DE">Auf dieser Webseite werden technisch
notwendige Cookies eingesetzt. Es handelt sich dabei um kleine Textdateien, die
in bzw. von Ihrem Internetbrowser auf Ihrem Computersystem gespeichert
werden.&nbsp;</span></p>

<p className="Paragraph"><b><span lang="DE">(2) Rechtsgrundlage</span></b></p>

<p className="Paragraph"><span lang="DE">Rechtsgrundlage für diese Verarbeitung ist
Art. 6 Abs.1 f) DSGVO.</span></p>

<p className="Paragraph"><b><span lang="DE">(3) Berechtigtes Interesse</span></b></p>

<p className="Paragraph"><span lang="DE">Unser berechtigtes Interesse ist die
Funktionsfähigkeit unserer Webseite. Die durch technisch notwendige Cookies
erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.
Dadurch wird Ihr Interesse am Datenschutz gewahrt.</span></p>

<p className="Paragraph"><b><span lang="DE">(4) Speicherdauer</span></b></p>

<p className="Paragraph"><span lang="DE">Die technisch notwendigen Cookies werden im
Regelfall mit dem Schließen des Browsers gelöscht. Dauerhaft gespeicherte
Cookies haben eine unterschiedlich lange Lebensdauer von einigen Minuten bis zu
mehreren Jahren.</span></p>

<p className="Paragraph"><b><span lang="DE">(5) WIDERSPRUCHSRECHT</span></b></p>

<p className="Paragraph"><b><span lang="DE">Falls Sie die Speicherung dieser Cookies
nicht wünschen, deaktivieren Sie bitte die Annahme dieser Cookies in Ihrem
Internetbrowser. Dies kann aber eine Funktionseinschränkung unserer Webseite
zur Folge haben. Dauerhaft gespeicherte Cookies können Sie ebenfalls jederzeit
über Ihren Browser löschen.</span></b></p>

 

 

<h2 className="pt-5">§ 5 Newsletter</h2>

 

<p className="Paragraph"><b><span lang="DE">(1) Verarbeitungszweck</span></b></p>

<p className="Paragraph"><span lang="DE">Bei Anmeldung zum Newsletter wird Ihre
E-Mail-Adresse für Werbezwecke genutzt, d.h. im Rahmen des Newsletters
informieren wir Sie insbesondere über Produkte aus unserem Sortiment. Zu
statistischen Zwecken können wir auswerten, welche Links im Newsletter geklickt
werden. Dabei ist für uns nicht erkennbar, welche konkrete Person geklickt hat.
Die nachstehende Einwilligung haben Sie gesondert oder ggf. im Verlauf des
Bestellprozesses ausdrücklich erteilt: Newsletter-Anmeldung</span></p>

<p className="Paragraph"><b><span lang="DE">(2) Rechtsgrundlage</span></b></p>

<p className="Paragraph"><span lang="DE">Rechtsgrundlage für diese Verarbeitung ist
Art. 6 Abs. 1 a) DSGVO.</span></p>

<p className="Paragraph"><b><span lang="DE">(3) Empfängerkategorien</span></b></p>

<p className="Paragraph"><span lang="DE">ggf. Newsletterversandanbieter</span></p>

<p className="Paragraph"><b><span lang="DE">(4) Speicherdauer</span></b></p>

<p className="Paragraph"><span lang="DE">Ihre E-Mail-Adresse wird für den
Newsletter-Versand nur für die Dauer der gewünschten Anmeldung gespeichert.</span></p>

<p className="Paragraph"><b><span lang="DE">(5) Widerrufsrecht</span></b></p>

<p className="Paragraph"><span lang="DE">Ihre Einwilligung können Sie jederzeit mit
Wirkung für die Zukunft widerrufen. Wenn Sie den Newsletter nicht weiter
beziehen wollen, dann können Sie sich folgendermaßen abmelden: Per E-Mail</span></p>

 

 

<h2 className="pt-5">§ 6 Ihre Rechte als Betroffener</h2>

 

<p className="Paragraph"><span lang="DE">Werden personenbezogene Daten von Ihnen
verarbeitet, sind Sie Betroffener im Sinne der DSGVO und es stehen Ihnen
folgende Rechte gegenüber uns als Verantwortlichen zu:</span></p>

 

 

<p className="Paragraph"><b><span lang="DE">1. Recht auf Auskunft</span></b></p>

<p className="Paragraph"><span lang="DE">Sie können im Rahmen des Art. 15 DSGVO
Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten verlangen.</span></p>

 

<p className="Paragraph"><b><span lang="DE">2. Recht auf Berichtigung</span></b></p>

<p className="Paragraph"><span lang="DE">Sollten die Sie betreffenden Angaben nicht
(mehr) zutreffend sein, können Sie nach Art. 16 DSGVO eine Berichtigung
verlangen. Sollten Ihre Daten unvollständig sein, können Sie eine
Vervollständigung verlangen.</span></p>

 

<p className="Paragraph"><b><span lang="DE">3.&nbsp; Recht auf Löschung</span></b></p>

<p className="Paragraph"><span lang="DE">Sie können unter den Bedingungen des Art. 17
DSGVO die Löschung Ihrer personenbezogenen Daten verlangen.</span></p>

 

<p className="Paragraph"><b><span lang="DE">4. Recht auf Einschränkung der Verarbeitung</span></b></p>

<p className="Paragraph"><span lang="DE">Sie haben im Rahmen der Vorgaben des Art. 18
DSGVO das Recht, eine Einschränkung der Verarbeitung der Sie betreffenden Daten
zu verlangen.</span></p>

 

<p className="Paragraph"><b><span lang="DE">5. Recht auf Datenübertragbarkeit</span></b></p>

<p className="Paragraph"><span lang="DE">Sie haben nach Art. 20 DSGVO das Recht, die
Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in
einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder
die Übermittlung an einen anderen Verantwortlichen zu verlangen.</span></p>

 

<p className="Paragraph"><b><span lang="DE">6. Recht auf Widerruf der
datenschutzrechtlichen Einwilligungserklärung</span></b></p>

<p className="Paragraph"><span lang="DE">Sie haben nach Art. 7 Abs. 3 DSGVO das Recht,
Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Die
Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
Verarbeitung wird dadurch nicht berührt.</span></p>

 

<p className="Paragraph"><b><span lang="DE">7. Recht auf Beschwerde bei einer
Aufsichtsbehörde</span></b></p>

<p className="Paragraph"><span lang="DE">Wenn Sie der Ansicht sind, dass die
Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO
verstößt, steht Ihnen nach Art. 77 DSGVO das Recht auf Beschwerde bei einer
Aufsichtsbehörde (insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts,
ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes) zu.</span></p>

<p className="Paragraph"><b><span lang="DE">&nbsp;&nbsp;</span></b></p>

<p className="Paragraph"><span lang="DE"><br/>
<br/>
<br/>
</span></p>

<p className="Paragraph"><b><span lang="DE">Bitte beachten Sie auch Ihr
Widerspruchsrecht nach Art. 21 DSGVO:</span></b></p>

 

<p className="Paragraph"><b><span lang="DE">a) Allgemein: begründeter Widerspruch
erforderlich</span></b></p>

<p className="Paragraph"><b><span lang="DE">Erfolgt die Verarbeitung Sie betreffender
personenbezogener Daten </span></b></p>

<p className="Paragraph"><b><span lang="DE">- zur Wahrung unseres überwiegenden
berechtigten Interesses (Rechtsgrundlage nach Art. 6 Abs. 1 f) DSGVO) oder</span></b></p>

<p className="Paragraph"><b><span lang="DE">- im öffentlichen Interesse
(Rechtsgrundlage nach Art. 6 Abs. 1 e) DSGVO), </span></b></p>

<p className="Paragraph"><b><span lang="DE">haben Sie das Recht, jederzeit aus Gründen,
die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung
Widerspruch einzulegen; dies gilt auch für ein auf die Bestimmungen der DSGVO
gestütztes Profiling.</span></b></p>

 

<p className="Paragraph"><b><span lang="DE">Im Fall des Widerspruchs verarbeiten wir
die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, wir
können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der
Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</span></b></p>

 

<p className="Paragraph"><b><span lang="DE">b) Sonderfall Direktwerbung: einfacher
Widerspruch genügt</span></b></p>

<p className="Paragraph"><b><span lang="DE">Werden die Sie betreffenden
personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie
das Recht, jederzeit und ohne Angabe von Gründen Widerspruch gegen diese
Verarbeitung einzulegen; dies gilt auch für das Profiling, soweit es mit
solcher Direktwerbung in Verbindung steht.</span></b></p>

 

<p className="Paragraph"><b><span lang="DE">Widersprechen Sie der Verarbeitung für
Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen
Daten nicht mehr für diese Zwecke verarbeitet.</span></b></p>

 

 

<p className="Paragraph"><b><span lang="DE">Verantwortlicher
für die Datenverarbeitung:</span></b><span lang="DE"><br/>
rbs Redaktionsbüro Stutensee<br/>
Am Hasenbiel 13-15<br/>
76297 Stutensee<br/>
Telefon: +49 7244 73969-0<br/>
kontakt@rbsonline.de</span></p>

 

 

<p className="Paragraph"><span lang="DE">Diese Datenschutzerklärung wurde erstellt und
wird laufend aktualisiert durch die janolaw AG.</span></p>

        <a href="https://www.datenschutz-janolaw.de/datenschutzerklaerung/datenschutz-logo/zertifikat/index.html?shopId=1040544&amp;certificateId=ec74f02e44664c9e83ddc21a946796b0" target="_blank" rel="noreferrer">
            <img src="https://www.datenschutz-janolaw.de/export/system/modules/de.janodaten.opencms.hibernate/resources/images/logo/janolaw_datenschutz_210x101.png" alt=" janoProtect Logo" border="0"/>
        </a>

                </Col>
            </Row>
        </Container> 
    </section>   
    </React.Fragment>      
  )

}

export default Datenschutz;
