import React, { useEffect } from "react";
import FptProgrammItem from "./FptProgrammItem";
import { Modal, Table } from "react-bootstrap";
import { loadProgramm } from "../../store/actions/fpt";
import { useDispatch, useSelector } from "react-redux";


const FptProgrammModal = (props) => {

    const dispatch = useDispatch();
    const programm = useSelector(state => state.fpt[props.data.idFpt].programm);

    useEffect(() => {
        //console.log("loading", props.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        props.data.idFpt && props.data.xmlFile && dispatch(loadProgramm(props.data.idFpt, props.data.tag, props.data.xmlFile))
    }, [props.data]);

    const onModalHide = () => {
        props.onClose && props.onClose();
    }

    return (
        <Modal
            show={true}
            onHide={onModalHide}
            animation={false}
            dialogClassName="modal-dialog modal-lg RbsModal RbsProgrammModal"
        >
            <Modal.Header closeButton className='modal-header--sticky'>
                <Modal.Title>Programm <strong>{props.data.wochentag}</strong> {props.data.datum}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table borderless>
                    <tbody>
                        {programm && programm[props.data.tag] && programm[props.data.tag].map((item, index) =>
                            <FptProgrammItem key={index} idFpt={props.data.idFpt} data={item} />
                        )}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}
export default FptProgrammModal;