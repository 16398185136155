import React from 'react';
import ScrollspyNav from "react-scrollspy-nav";
import { Container, Nav } from 'react-bootstrap';
import ScrollspyNavItemHome from "../layout/ScrollspyNavItemHome";
import SubNavigationTeaserButton from "../layout/SubNavigationTeaserButton";
import Bilder from "../sections/Bilder";
import Programm from "../sections/Rueckblick/Programm";
import Firmenteilnehmer from "../sections/Rueckblick/Firmenteilnehmer";
import Redaktionen from "../sections/Rueckblick/Redaktionen";
import Stimmen from "../sections/Stimmen";
import Videos from "../sections/Videos";
import RbsPage from '../controls/RbsPage';

const Rueckblick = () => {

  return (
    <RbsPage>

      <nav className="navbar sticky-md-top subnav">
        <Container>
          <ScrollspyNav scrollTargetIds={["Bilder", "Programm", "Firmenteilnehmer", "Redaktionen", "Videos", "Stimmen"]} offset={0} activeNavClass="active" scrollDuration="10" headerBackground="true">
            <Nav variant="pills">
              <ScrollspyNavItemHome />
              <Nav.Item>
                <Nav.Link href="#Bilder">Bilder</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Programm">Programm</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Firmenteilnehmer">Firmenteilnehmer</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Redaktionen">Redaktionen</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Stimmen">Stimmen</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#Videos">Videos</Nav.Link>
              </Nav.Item>
            </Nav>
          </ScrollspyNav>
        </Container>
        <SubNavigationTeaserButton />
      </nav>

      <section id="Bilder" className='scrollIntoViewFix'><Bilder /></section>
      <section id="Programm" className="custom-section-grey"><Programm /></section>
      <section id="Firmenteilnehmer"><Firmenteilnehmer /></section>
      <section id="Redaktionen"><Redaktionen /></section>
      <section id="Stimmen" className="custom-section-grey"><Stimmen /></section>
      <section id="Videos" className='bg-dark'><Videos /></section>

    </RbsPage>
  )

}

export default Rueckblick;
