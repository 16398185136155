import axios from 'axios';
import { settings } from './settings';

 const webservice = () => {
    axios.defaults.baseURL = settings.network.baseUrl;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Accept'] = 'application/json';

}


export default webservice;
