import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ProgrammRaster2024 from './Programmraster2024';
import { ReactComponent as ImageFiletypePdf } from '../../../images/filetype-pdf.svg';


function Programm2024() {

  return (
    <React.Fragment>

      <Container>
        <Row className='pt-5 justify-content-center'>
          <Col className='text-end'>
            <h1 className="text-center pt-2 pb-5 mb-2">Programm 2024</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <ProgrammRaster2024 />
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <Button
              variant='rbs-button-orange'
              className='px-4'
              size='lg'
              href="https://files.rbsonline.de/Files/Programm_und_Kontaktmesse_2024.pdf"
              target='_blank'              
            ><ImageFiletypePdf className="ImageFiletypePdf" /> Download Pdf</Button>
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  );
}

export default Programm2024;