import React, { useRef, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Card } from 'react-bootstrap';
import ImageThemen from '../../../images/home/1920x466.png';
import RbsLogoSlider from '../../controls/RbsLogoSlider';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../store/actions/global';
import { settings } from "../../../config/settings";
import { ReactComponent as ImageChevronLeft } from '../../../images/chevron-left.svg';
import { ReactComponent as ImageChevronRight } from '../../../images/chevron-right.svg';
import { loadRedaktionen } from '../../../store/actions/fpt';

const dummyImage = require('../../../images/redaktion-dummy.png');

const countCols = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 4
}

const Redaktionen2024 = (props) => {

  const idFpt = 'fpt035';
  const sliderRef = useRef();
  const dispatch = useDispatch();
  const redaktionen = useSelector(state => state.fpt[idFpt].redaktionen);
  useSelector(state => console.log("state", state))

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if( !redaktionen){
      dispatch(loadRedaktionen(idFpt));  
    }
  }, []);

  const bttnPrevClick = () => {
    dispatch(setLoading(true));
    sliderRef.current.movePrev();
  }

  const bttnNextClick = () => {
    dispatch(setLoading(false));
    sliderRef.current.moveNext();
  }

  const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  return (
    <React.Fragment>
      <img src={ImageThemen} className='img-fluid rbs-redaktionen-bild' alt='Redaktionen'></img>
      <Container>
        <Row>
          <Col className='text-center'>
            <h1 className="pb-4">Redaktionen 2024</h1>
            <p className="lead pb-4">Diese Redaktionen weren vertreten:</p>
          </Col>
        </Row>
        <Row className='mb-5'>
          <Col>
            <RbsLogoSlider
              ref={sliderRef}
              countCols={countCols}
              showDescription={true}
              logos={redaktionen && shuffle(redaktionen.map(f => ({
                url: f.logoFile ? settings.network.logoUrl + f.logoFile : undefined,
                description: f.firmenname
              })))}
              speed={3000}
            />
          </Col>
        </Row>
        <Row className='mt-5 text-center'>
          <Col>
            <Button variant='rbs-button-orange' size='lg' className='me-2' onClick={bttnPrevClick}><ImageChevronLeft width={16} height={16} fill='#fff' /></Button>
            <Button variant='rbs-button-orange' size='lg' href="/Anmeldung">Zur Anmeldung</Button>
            <Button variant='rbs-button-orange' size='lg' className='ms-2' onClick={bttnNextClick}><ImageChevronRight width={16} height={16} fill='#fff' /></Button>
          </Col>
        </Row>
      </Container>

    </React.Fragment>

  );
}

export default Redaktionen2024;