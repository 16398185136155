import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import FaqItem from '../../controls/FaqItem';



function FaqVortrag(props) {

    return (

        <FaqItem 
            id="FaqKontaktpflege"
            header="Kontaktpflege"
            visible={props.visible}
        >
            <Row>
                <Col xs={12} lg={6} className='pe-xl-3'>
                    <p>
                        Kontaktpflege ist eines der wichtigen Ziele der Veranstaltung. Vortragspausen und der Kontaktabend bieten sich dafür an. Oft
                        sind mehr Journalist:innen anwesend, als Sie in der vorhandenen Zeit sprechen können. Planen Sie Ihre Kontaktaufnahme und -pflege
                        daher bereits vor der Veranstaltung. Nehmen mehrere Personen Ihres Unternehmens teil, können Sie sich die Journalist:innen vorab "zuteilen".
                    </p>
                </Col>
                <Col xs={12} lg={6} className='pe-xl-5'>
                    <p>Im passwortgeschützten <a target='_blank' href="https://portal.fachpresse.live">Portal</a> sehen Sie, wer sich für welchen Veranstaltungsblock
                        angemeldet hat. Haben Sie den Mut,
                        auf Redakteur:innen zuzugehen, auch wenn sich diese oft in scheinbar geschlossenen Gruppen versammeln.
                    </p>
                    <Alert>
                        Vergessen Sie auch nicht, nach der Veranstaltung Ihre neu geknüpften Kontakte in Ihren <strong>Presseverteiler</strong> mitaufzunehmen.
                    </Alert>
                </Col>
            </Row>
        </FaqItem >
    );
}

export default FaqVortrag;