import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from '../components/layout/MainLayout';
import Startseite from '../components/pages/Startseite';
import Kontakt from '../components/pages/Kontakt';
import Impressum from '../components/pages/Impressum';
import Agb from '../components/pages/Agb';
import Datenschutz from '../components/pages/Datenschutz';
import Fachpressetage2025 from '../components/pages/Fachpressetage2025';
import Fachpressetage2024 from '../components/pages/Fachpressetage2024';
import Fachpressetage2023 from '../components/pages/Fachpressetage2023';
import Rueckblick from '../components/pages/Rueckblick';
import Anmeldung from '../components/pages/Anmeldung';
import Anmeldung2025 from '../components/pages/Anmeldung2025';
import FAQ from '../components/pages/FAQ';
import PageNotFound from '../components/pages/PageNotFound';

const AppRouter = () => {
    
    useEffect(() => {
      function scrollIntoViewWithOffset(element, topOffset) {
        // Get the element's position.
        let position = element.getBoundingClientRect();
      
        // Calculate the top and bottom of the element within the viewport.
        let top = position.top + window.scrollY;
      
        // Scroll the viewport so that the element is visible.
        window.scrollTo(0, top - topOffset);
      }
  
      const {hash} = window.location;

      if (hash !== '') {
        let retries = 0;
        const id = hash.replace('#', '');
        const scroll = () => {
          retries += 0;
          if (retries > 50) return;
          const element = document.getElementById(id);
          if (element) {
            setTimeout(() => scrollIntoViewWithOffset(element, 45), 100);
          } else {
            setTimeout(scroll, 100);
          }
        };
        scroll();
      }
    })

    return (
        <BrowserRouter>
            <MainLayout>
                <Routes>
                    <Route path="/" element={<Startseite />} />
                    <Route path="Fachpressetage2025" element={<Fachpressetage2025 />} />
                    <Route path="Fachpressetage2024" element={<Fachpressetage2024 />} />
                    <Route path="Fachpressetage2023" element={<Fachpressetage2023 />} />
                    <Route path="Rueckblick" element={<Rueckblick />} />
                    <Route path="Anmeldung" element={<Anmeldung />} />
                    <Route path="FAQ/:selectedId?" element={<FAQ />} />
                    <Route path="Kontakt" element={<Kontakt />} />
                    <Route path="Impressum" element={<Impressum />} />
                    <Route path="Agb" element={<Agb />} />
                    <Route path="Datenschutz" element={<Datenschutz />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </MainLayout>
        </BrowserRouter>
    )
}

export default AppRouter;
