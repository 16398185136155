import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ImageHotel from '../../../images/genohotel.jpg';
import FaqItem from '../../controls/FaqItem';

function FaqAnreise(props) {

	return (
		<FaqItem
			id="FaqAnreise"
			header="Veranstaltungsort und Anreise"
			visible={props.visible}
		>

			<Row className="gx-lg-4 gx-xl-5">
				<Col className="col-12 col-lg-6 mb-6">

					<h4>GenoHotel Karlsruhe (ehemals AkademieHotel)</h4>
					<p>

						Am Rüppurrer Schloß 40<br />
						76199 Karlsruhe<br />
						Tel: <a href='tel:+497219898-0'>+49 721 9898-0</a><br />
						Fax: +49 721/9898-428<br />
						E-Mail: <a href="mailto:hotel@genohotel-karlsruhe.de">hotel@genohotel-karlsruhe.de </a><br />
					</p>

					<h4>Anreise mit der Bahn:</h4>
					<ul>
						<li>S1 oder S11: KA Hbf ={'>'} Haltestelle Schloss Rüppurr (ca. 5 Minuten)</li>
						<li><a href="https://www.kvv.de/fahrplan/fahrplanauskunft.html" target='blank'>Zur Fahrplanauskunft
							des Karlsruher Verkehrsverbunds</a> </li>
					</ul>
					<h4>Anreise mit dem Auto:</h4>
					<p>
						Zur Navigation mit&nbsp;
						<a target='blank' href="https://www.google.de/maps/dir/''/@48.9813889,8.411314,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47970608bdcc7165:0x187e3156da82d196!2m2!1d8.4138889!2d48.9813889!3e0?entry=ttu">GoogleMaps</a>
					</p>
				</Col>
				<Col className="col-12 col-lg-6 mb-6">
					<img src={ImageHotel} style={{ width: '100%' }} />
				</Col>
			</Row>

		</FaqItem>
	);
}

export default FaqAnreise;