import React, { useRef, forwardRef, useImperativeHandle } from "react";
import Slider from "react-slick";
import useBreakpoint from 'use-breakpoint'
import { settings } from "../../config/settings";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RbsLogoSlider = forwardRef((props, ref) => {

    const sliderRef = useRef();

    useImperativeHandle(ref, () => ({

        moveNext() {
            sliderRef && sliderRef.current.slickNext();
        },

        movePrev() {
            sliderRef && sliderRef.current.slickPrev();
        }

    }));

    const { breakpoint } = useBreakpoint(
        settings.style.breakpoints,
        'desktop'
    )

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <ChevronRight
            className={className}
            style={{ ...style, display: "block", color: "#000" }}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <ChevronLeft
            className={className}
            style={{ ...style, display: "block", color: "#000" }}
            onClick={onClick}
          />
        );
      }

    const sliderSettings = {
        dots: !props.hideDots,
        arrows: true,
        rows: 3,
        infinite: true,
        speed: 500,
        slidesToShow: props.countCols[breakpoint] ? props.countCols[breakpoint] : 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: props.speed,
        easing: 'easeOutBounce',
        pauseOnHover: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };


    return (
        <div className="rbsLogoSlider">
            <Slider ref={sliderRef} {...sliderSettings}>

            {props.logos && props.logos.map((logo, index) =>
                <div key={index} className="rbs-redaktionen-slider-item">
                    
                    {!!logo.url ?
                        <div className="d-flex justify-content-center">
                                <img className="rbs-redaktionen-slider-item-image" src={logo.url} alt={logo.description}/>
                        </div>  
                        :
                        <div className="d-flex justify-content-center">
                            <div className="d-flex align-items-center rbs-redaktionen-slider-item-text"> 
                                <span>{logo.description}</span>
                            </div>                      
                        </div>
                    }
                    
                </div>
            )}

            </Slider>
        </div>
    )

});

export default RbsLogoSlider;