import React, { useEffect } from "react";
import { Button } from "react-bootstrap";


const RbsCaptcha = (props) => {

    useEffect(() => {
        buildCaptcha();
    }, []);

    useEffect(() => {
        props.userInput && checkCaptcha(props.userInput, captcha);
    }, [props.userInput]);


    const [captcha, setCaptcha] = React.useState(undefined);

    const checkCaptcha = (userInput, c) => {
        props.onCaptchaCheck && props.onCaptchaCheck(userInput == c);
    }

    const buildCaptcha = () => {
        const captcha = generateChars(props.length ? props.length : 6);
        setCaptcha(captcha);
        drawCaptcha(captcha);
        checkCaptcha(props.userInput, captcha);
    }

    const generateChars = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const drawCaptcha = (content) => {
        const elCanvas = document.getElementById(props.id)
        if (!elCanvas) return;

        const ctx = elCanvas.getContext("2d");
        ctx.clearRect(0, 0, elCanvas.width, elCanvas.height);
        ctx.font = "33px serif";
        ctx.fillStyle = '#0000ff';
        ctx.fillText(content, 10, 40);
    }

    return (
        <div className="rbs-react-captcha" style={{width: '200px', height: '75px'}}>
            <canvas height={60} width={120} id={props.id} style={{marginRight: '10px', backgroundColor: '#eaeaff', float: 'left' }}></canvas>
            <Button onClick={() => buildCaptcha()} style={{background: '#eee', borderColor: '#888'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                </svg>
            </Button>

        </div>
    );
}

export default RbsCaptcha;