import React, { useState } from "react"
import { Col, Row } from "react-bootstrap";
import { ReactComponent as ImageCaretRight } from '../../../images/caret-right-fill.svg';
import ImageGreenCircle from '../../../images/green-circle.png';
import FptProgrammModal from "../../controls/FptProgrammModal";


const ProgrammRaster2024 = (props) => {

    const [programmData, setProgrammData] = useState({
        tag: undefined,
        datum: undefined,
        wochentag: undefined,
        xmlFile: undefined,
        idFpt: undefined
    });

    const [showModal, setShowModal] = useState(false);

    //#region FormEvents

    const bttnDetailTag1Click = () => {
        setProgrammData({
            tag: 'tag1',
            datum: '06.02.2024',
            wochentag: 'Dienstag',
            xmlFile: 'fpt035_tag1.xml',
            idFpt: 'fpt035'
        });
        setShowModal(true);
    }

    const bttnDetailTag2Click = () => {
        setProgrammData({
            tag: 'tag2',
            datum: '07.02.2024',
            wochentag: 'Mittwoch',
            xmlFile: 'fpt035_tag2.xml',
            idFpt: 'fpt035'
        });

        setShowModal(true);
    }

    //#endregion

    return (
        <React.Fragment>
            <Row className='pb-4 pb-lg-0'>
                <Col xs={12} md={6} className='pb-4'>
                    <div className="d-flex flex-column programm-wrapper" onClick={bttnDetailTag1Click}>
                        <div className="programm-white shadow-sm">
                            <strong>Dienstag</strong> 06.02.2024
                        </div>
                        <div className="programm-orange shadow-sm">
                            Netzwerke<br />
                            Maschinenbau<br />
                            Prozessautomation<br />
                            Messtechnik<br />
                            <br />
                        </div>
                        <div className="programm-black shadow-sm">
                            Meet-the-Press Abend
                        </div>
                        <div className="programm-link">
                            <ImageCaretRight className="ProgrammDetailsButtonCaret" /> Details anzeigen
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className="d-flex flex-column programm-wrapper" onClick={bttnDetailTag2Click}>
                        <div className="programm-white shadow-sm">
                            <strong>Mittwoch</strong> 07.02.2024
                        </div>
                        <div className="programm-orange shadow-sm">
                            <br />
                            HMI & Steuerungstechnik<br />
                            Sensorik
                            <br />
                            <br />
                        </div>
                        <div className="programm-link">
                            <ImageCaretRight className="ProgrammDetailsButtonCaret" /> Details anzeigen
                        </div>
                    </div>
                </Col>
            </Row>

            {showModal &&
                <FptProgrammModal onClose={() => setShowModal(false)} data={programmData} />
            }

        </React.Fragment>

    )

}
export default ProgrammRaster2024;